import { QueryCodeViewer } from '@/modules/docs/components';
import React, { useState } from 'react';
import { Button, Form, Input, message, Select, Typography } from 'antd';
import axios from 'axios';

import { YcEnums } from 'yc-interfaces';
import { JsonViewer } from '@/components';
import { MApp } from '@/modeles';

import './placement-add-component.css';

const { Title } = Typography;

interface PlacementAdd {
  name: string;
  type: string;
  applicationKey: string;
}

interface PlacementAddComponentProps {
  displayApps: MApp[];
  apiKey: string;
}

function handleFormValidation(placement?: PlacementAdd) {
  if (!placement) {
    message.warning('Form not initialized.');
    return null;
  }

  return placement;
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/placements/add`;

const resultSchema = JSON.parse(`{
  "data": {
    "key": "",
    "name": "",
    "type": "interstitial|rewarded|banner",
    "isActive": true,
    "applicationKey": "",
    "Application": {
      "key": "",
      "name": "",
      "platform": "ios|android",
      "bundleId": "",
      "appleId": "",
      "isCallbackCreated": true,
      "storeUrl": "",
      "Icon": {
        "url": ""
      },
      "nbCampaigns": 0,
      "nbCampaignsActive": 0,
      "nbPlacements": 0,
      "nbPlacementsActive": 0
    }
  },
  "date": 0
}`);

export const PlacementAddComponent: React.FC<PlacementAddComponentProps> = ({ apiKey, displayApps }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [placement, setPlacement] = useState<PlacementAdd>();

  const fetchPlacement = async () => {
    const placementToCreate = handleFormValidation(placement);

    if (!placementToCreate) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(baseUrl, {
        name: placementToCreate.name,
        type: placementToCreate.type,
        applicationKey: placementToCreate.applicationKey,
      }, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="placement-add-component">
      <Title level={3}>API Documentation: Create Placement</Title>
      <Form layout="vertical" onValuesChange={(v) => setPlacement({ ...placement, ...v })}>
        <div className='row'>
          <div className='col-3'>
            <Form.Item label="Name" name='name'>
              <Input type='text' placeholder='name' />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Type" name='type'>
              <Select placeholder="Placement type" options={Object.values(YcEnums.PlacementTypes).map((type) => ({ value: type, label: type }))} />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Application" name='applicationKey'>
              <Select placeholder="Choose an app" options={displayApps.map((app) => ({ value: app.key, label: app.name }))} />
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <Button type="primary" onClick={fetchPlacement} loading={isLoading}>
            Create Placement
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={baseUrl} apiKey={apiKey} method='post' payload={placement} />
    </div>
  );
};
