import React from 'react';
import { Button, Tooltip, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import './code-viewer.scss';

interface CodeViewerProps {
  code: string;
  language: string;
}

export const CodeViewer: React.FC<CodeViewerProps> = ({ code, language }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    message.success(`${language} code copied to clipboard!`);
  };

  return (
    <div className="code-viewer">
      <div className="code-header">
        <span className="language-label">{language}</span>
        <Tooltip title={`Copy ${language} code to clipboard`}>
          <Button
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            className="copy-button"
          >Copy</Button>
        </Tooltip>
      </div>
      <pre className="code-content">
        <code>{code}</code>
      </pre>
    </div>
  );
};
