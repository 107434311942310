import {QueryCodeViewer} from '@/modules/docs/components';
import React, { useState } from 'react';
import { Button, Form, Input, message, Select, Typography } from 'antd';
import axios from 'axios';

import { JsonViewer } from '@/components';

import './app-add-component.css';

const { Title } = Typography;

interface AppAdd {
  platform: 'ios' | 'android';
  name?: string;
  bundleId?: string;
  appleId?: number;
}

interface AppAddComponentProps {
  apiKey: string;
}

function handleFormValidation(app?: AppAdd) {
  if (!app) {
    message.warning('Form not initialized.');
    return null;
  }

  if (app.platform === 'ios' && !app.appleId) {
    message.warning('iOS need a valid appleId');
    return null;
  }

  return app;
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/apps/add`;

const resultSchema = JSON.parse(`{
  "data": {
    "key": "",
    "name": "",
    "platform": "ios|android",
    "bundleId": "",
    "appleId": "",
    "isCallbackCreated": true,
    "storeUrl": "",
    "Icon": {
      "url": ""
    },
    "nbCampaigns": 0,
    "nbCampaignsActive": 0,
    "nbPlacements": 0,
    "nbPlacementsActive": 0
  },
  "date": 0
}`);

export const AppAddComponent: React.FC<AppAddComponentProps> = ({ apiKey }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [app, setApp] = useState<AppAdd>({ platform: 'android' });

  const fetchApp = async () => {
    const appToCreate = handleFormValidation(app);

    if (!appToCreate) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(baseUrl, {
        platform: appToCreate.platform,
        name: appToCreate.name,
        bundleId: appToCreate.bundleId,
        appleId: appToCreate.appleId || undefined,
      }, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app-add-component">
      <Title level={3}>API Documentation: Create App</Title>
      {/* @ts-ignore */}
      <Form layout="vertical" onValuesChange={(v) => setApp({...app, ...v})}>
        <Form.Item label="Name" name='name'>
          <Input type='text' placeholder='Input the name of your app' />
        </Form.Item>
        <Form.Item label="Platform" name='platform' initialValue='android'>
          <Select placeholder="Select a platform" options={[{ value: 'android', label: 'Android' }, { value: 'ios', label: 'iOS' }]} />
        </Form.Item>
        <Form.Item label="Bundle Id" name='bundleId'>
          <Input type='text' placeholder='Input the bundle of your app : com.example.myApp' />
        </Form.Item>
        {app?.platform === 'ios' && <Form.Item label="Apple Id" name="appleId">
          <Input type="text" placeholder="Input the Apple ID of your app : 1234567890" />
        </Form.Item>}
        <Form.Item>
          <Button type="primary" onClick={fetchApp} loading={isLoading}>
            Create App
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={baseUrl} apiKey={apiKey} method='post' payload={app} />
    </div>
  );
};
