import { useEffect, useState } from "react";
import { UnorderedListOutlined } from '@ant-design/icons';

import { YcTabs, YcTitle } from "../../../../components";
import { profileService } from "../../../../services/profile.service";
import { SearchComponent } from "./components/search";
import { Link } from "react-router-dom";

import './docs-report-advertising.page.scss';

export function DocsReportAdvertisingPage() {

  const [tab, _setTab] = useState("search");
  const [reportKey, setReportKey] = useState("");

  useEffect(() => {
    profileService.viewManagerOrReportKey("reportKey").then((key) => {
      setReportKey(key || '');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='docs-report-advertising'>
      <YcTitle label="Docs > Report Advertising"></YcTitle>
      <p>
        In this api you can have reporting advertising (UA).
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "search", label: "Search", icon: <UnorderedListOutlined /> },
      ]} />
      <div className="doc-container">
        {!!reportKey ?
          <>
            {tab === "search" &&
              <SearchComponent apiKey={reportKey} />
            }
          </> : <div>You need to generate an api key <Link to='/profile'>here</Link> to use this documentation</div>
        }
      </div>
    </div>
  );
}