export interface Total {
  impressions: number;
  clicks: number;
  installs: number;
  spend: number;
  ctr: number;
  ir: number;
  ecpi: number;
  ecpm: number;
}

export interface ResultLine extends Total {
  id: number;
  date?: string;
  hourOfDay?: string;
  platform?: string;
  applicationKey?: string;
  campaignKey?: string;
  countryCode?: string;
  source?: string;
  placementType?: string;
  creativeType?: string;
  adName?: string;
}

export const PARAMS_FILTERS = ['granularities', 'hours', 'countries', 'platforms', 'applications', 'campaigns', 'creativeTypes'];
export const IS_FILTERS = [
  { filterKey: 'isGranularity', searchKey: 'granularity' },
  { filterKey: 'isHourOfDay', searchKey: 'hourOfDay' },
  { filterKey: 'isApplication', searchKey: 'application' },
  { filterKey: 'isCampaign', searchKey: 'campaign' },
  { filterKey: 'isAd', searchKey: 'ad' },
  { filterKey: 'isCountry', searchKey: 'country' },
  { filterKey: 'isPlatform', searchKey: 'platform' },
  { filterKey: 'isPlacementType', searchKey: 'placementType' },
  { filterKey: 'isSource', searchKey: 'source' },
  { filterKey: 'isCreativeType', searchKey: 'creativeType' },
];