import React, { useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import axios from 'axios';

import { JsonViewer } from '@/components';
import { MApp } from '@/modeles';
import { QueryCodeViewer } from '@/modules/docs/components';

import './app-view-component.css';

const { Title } = Typography;

interface AppViewComponentProps {
  apiKey: string;
  allApps: MApp[];
}

interface viewParams {
  fields?: string[];
}

const fieldOptions = [ 'platform', 'bundleId', 'appleId', 'storeUrl', 'icon'];
const baseUrl = `${process.env.REACT_APP_API_URL}/api/apps/view`;

const resultSchema = JSON.parse(`{
  "data": {
    "key": "",
    "name": "",
    "platform": "ios|android",
    "bundleId": "",
    "appleId": "",
    "isCallbackCreated": true,
    "storeUrl": "",
    "Icon": {
      "url": ""
    },
    "nbCampaigns": 0,
    "nbCampaignsActive": 0,
    "nbPlacements": 0,
    "nbPlacementsActive": 0
  },
  "date": 0
}`);

export const AppViewComponent: React.FC<AppViewComponentProps> = ({ apiKey, allApps }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [key, setKey] = useState<string>();
  const [params, setParams] = useState<viewParams>();

  const onChangeParams = (values: viewParams) => {
    const updatedParams = { ...params };
    Object.keys(values)?.forEach((property) => {
      if (values[property] === null || values[property] === undefined || values[property].length <= 0) {
        delete updatedParams[property];
        setParams(updatedParams);
      } else {
        setParams({ ...params, [property]: values[property] });
      }
    })
  };

  const fetchApp = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/${key}`, {
        headers: { Authorization: apiKey },
        params: (params?.fields?.length) ? {
          ...(params?.fields?.length ? { fields: params.fields.join(',') } : {}),
        } : undefined,
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app-view-component">
      <Title level={3}>API Documentation: Get App</Title>
      <Form layout="vertical" onValuesChange={(values) => { onChangeParams(values); }}>
        <Form.Item label="App Key" initialValue={key}>
          <Select
            showSearch
            placeholder="Select app key to view"
            onChange={(value) => {setKey(value)}}
            options={allApps.map(app => ({ value: app.key, label: `${app.name} (${app.platform})` }))}
          />
        </Form.Item>
        <Form.Item label="Fields" name='fields'>
          <Select
            mode="multiple"
            placeholder="Select fields to include in the response"
            value={params?.fields}
            options={fieldOptions.map(field => ({ value: field }))}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetchApp} loading={isLoading}>
            Fetch App
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={`${baseUrl}/${key}`} apiKey={apiKey} method='get' params={params} />
    </div>
  );
};
