import { MApp } from '@/modeles';
import React, { useState } from 'react';
import { Button, Form, Input, Select, Typography } from 'antd';
import axios from 'axios';

import { JsonViewer } from '@/components';
import { QueryCodeViewer } from '@/modules/docs/components';

import './app-edit-component.css';

const { Title } = Typography;

interface ApplicationEdit {
  key: string,
  name?: string;
}

interface AppEditComponentProps {
  apiKey: string;
  allApps: MApp[];
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/apps/edit`;

const resultSchema = JSON.parse(`{
  "data": {
    "key": "",
    "name": "",
    "platform": "ios|android",
    "bundleId": "",
    "appleId": "",
    "isCallbackCreated": true,
    "storeUrl": "",
    "Icon": {
      "url": ""
    },
    "nbCampaigns": 0,
    "nbCampaignsActive": 0,
    "nbPlacements": 0,
    "nbPlacementsActive": 0
  },
  "date": 0
}`);

export const AppEditComponent: React.FC<AppEditComponentProps> = ({ apiKey, allApps }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<ApplicationEdit>({key: allApps[0].key});

  const fetchApp = async () => {

    if (!application) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/${application?.key}`, {
        name: application.name,
      }, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanPayload = (values: any) => {
    const updatedApplication = { ...application as ApplicationEdit };
    Object.keys(values).forEach((property) => {
      if (values[property] === null || values[property] === undefined || values[property] === '') {
        delete updatedApplication[property];
        setApplication(updatedApplication);
      } else {
        setApplication({ ...application as ApplicationEdit, [property]: values[property] });
      }
    })
  };

  return (
    <div className="app-edit-component">
      <Title level={3}>API Documentation: Update App</Title>
      <Form layout="vertical" onValuesChange={(values) => { cleanPayload(values); }}>
        <Form.Item label="App Key" name='key' initialValue={application.key}>
          <Select
            showSearch
            placeholder="Select app key to view"
            options={allApps.map(app => ({ value: app.key, label: `${app.name} (${app.platform})` }))}
          />
        </Form.Item>
        <Form.Item label="Name" name='name' initialValue={application.name}>
          <Input type='text' placeholder='Input the name of your app' />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetchApp} loading={isLoading}>
            Update App
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={`${baseUrl}/${application?.key}`} apiKey={apiKey} method='post' payload={application} />
    </div>
  );
};
