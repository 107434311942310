import { JsonViewer } from '@/components/json-viewer/json-viewer';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography } from 'antd';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

import { QueryCodeViewer } from '@/modules/docs/components';
import { ReportingFilter } from '@/modules/reporting/components';
import { reportingBiddingService } from '@/services';
import { FilterTool } from '@/tools';
import { useReportingTable } from '@/hooks';
import { IS_FILTERS, PARAMS_FILTERS, ResultLine, Total } from '@/modules/reporting/constant/bidding.constant';

import './search-component.css';

const { Title } = Typography;

interface BiddingSearchComponentProps {
  apiKey: string;
}

interface Result {
  is: string[];
  total: Total;
  results: ResultLine[];
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/report/bidding/search`;

const resultSchema = JSON.parse(`{
  "data": {
    "total": {
      "requests": 0,
      "responses": 0,
      "noBids": 0,
      "bidRate": 0,
      "wins": 0,
      "winRate": 0,
      "loss": 0,
      "lossRate": 0,
      "errors": 0,
      "errorRate": 0,
      "impressions": 0,
      "displayRate": 0,
      "bills": 0,
      "revenue": null,
      "ecpm": null,
      "loadEnds": 0,
      "loadErrors": 0,
      "loadStarts": 0,
      "showEnds": 0,
      "showErrors": 0,
      "showStarts": 0
    },
    "results": [
      {
        "requests": 0,
        "responses": 0,
        "noBids": 0,
        "bidRate": 0,
        "wins": 0,
        "winRate": 0,
        "loss": 0,
        "lossRate": 0,
        "errors": 0,
        "errorRate": 0,
        "impressions": 0,
        "displayRate": 0,
        "bills": 0,
        "revenue": null,
        "ecpm": null,
        "loadEnds": 0,
        "loadErrors": 0,
        "loadStarts": 0,
        "showEnds": 0,
        "showErrors": 0,
        "showStarts": 0,
        "id": 0,
        "source": "",
        "countryCode": "",
        "date": "",
        "hourOfDay": null,
        "endpoint": "",
        "platform": "ios|android",
        "platformVersion": "",
        "placementType": "",
        "lossCode": 0
      }
    ]
  },
  "date": 0
}`);

function getParams(stringify: boolean, dates: [moment.Moment, moment.Moment], filters: Filters, params: any = {}) {
  const b = {
    ...(params || {}),
    dateStart: moment(dates[0]).format("YYYY-MM-DD"),
    dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
    granularity: filters?.isGranularity ? filters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
    hours: FilterTool.getParamsFilter(filters?.hours),
    endpoints: FilterTool.getParamsFilter(filters?.endpoints),
    countries: FilterTool.getParamsFilter(filters?.countries),
    sources: FilterTool.getParamsFilter(filters?.sources),
    platforms: FilterTool.getParamsFilter(filters?.platforms),
    platformVersions: FilterTool.getParamsFilter(filters?.platformVersions),
    placementTypes: FilterTool.getParamsFilter(filters?.placementTypes),
    lossCodes: FilterTool.getParamsFilter(filters?.lossCodes),
    is: FilterTool.getParamsIs(IS_FILTERS, filters),
  };
  if (stringify) {
    return qs.stringify(b, { encode: false });
  }
  return b;
}

export const SearchComponent: React.FC<BiddingSearchComponentProps> = ({ apiKey }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<any>();

  const {
    filters, dates, onSetFilters,
  } = useReportingTable<Result, ResultLine>({
    service: reportingBiddingService,
    getParams,
    isFilters: IS_FILTERS,
    paramsFilters: PARAMS_FILTERS,
    initFCItemStates: FilterTool.initFCItemStates,
  });

  const filterProps: FilterPropType[] = useMemo(
    () => [
      { key: 'granularities', itemKey: 'granularities', isKey: 'isGranularity', label: 'Granularity' },
      { key: 'hours', itemKey: 'hours', isKey: 'isHourOfDay', label: 'Hours', mode: 'multiple' as const },
      { key: 'countries', itemKey: 'countries', isKey: 'isCountry', label: 'Countries', mode: 'multiple' as const },
      { key: 'sources', itemKey: 'sources', isKey: 'isSource', label: 'Sources', mode: 'multiple' as const },
      { key: 'platforms', itemKey: 'platforms', isKey: 'isPlatform', label: 'Platforms', mode: 'multiple' as const },
      { key: 'platformVersions', itemKey: 'platformVersions', isKey: 'isPlatformVersion', label: 'Platform Versions', mode: 'multiple' as const },
      { key: 'placements', itemKey: 'placements', isKey: 'isPlacement', label: 'Placements', mode: 'multiple' as const },
      { key: 'placementTypes', itemKey: 'placementTypes', isKey: 'isPlacementType', label: 'Placement Types', mode: 'multiple' as const },
    ].map((f) => ({ ...f, filters: filters, onSetFilters: onSetFilters, show: true })),
    [filters, onSetFilters],
  );

  useEffect(() => {
    const paramsObject = getParams(true, dates, filters).split('&').reduce((acc, param) => {
      const [key, value] = param.split('=');
      acc[key] = value.split(',');
      return acc;
    }, {});
    setParams(paramsObject)
  }, [dates, filters, getParams])

  const fetchBiddingSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}?${getParams(true, dates, filters)}`, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="search-component">
      <Title level={3}>API Documentation: Get Bidding search</Title>
      <ReportingFilter
        datePresets={['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date'] as const}
        dates={dates}
        filterProps={filterProps}
        loading={isLoading}
        onDatesChange={() => { }}
      />

      <Button type="primary" onClick={fetchBiddingSearch} loading={isLoading}>
        Fetch Bidding search
      </Button>


      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={baseUrl} apiKey={apiKey} method='get' params={params} />
    </div>
  );
};
