import { CodeViewer } from '@/components';
import { Tabs, Typography } from 'antd';

const { TabPane } = Tabs;
const { Title } = Typography;

interface QueryCodeViewProps {
  url: string;
  apiKey: string;
  method: 'get' | 'post';
  payload?: any;
  file?: any;
  contentType?: string;
  params?: any;
}

export function QueryCodeViewer(props: QueryCodeViewProps) {
  const getParams = (values: string[]) => {
    if (values && values[0]) {
      return values.join(',').toString() ?? '';
    }
    return '';
  }

  const generateCurlCommand = () => {
    return `curl -X ${props.method.toUpperCase()} '${props.url}${props.params
        ? Object.keys(props.params)?.map((param, index) => {
          const paramValue = getParams(props.params[param]);
          return `${index === 0 ? '?' : '&'}${param}=${paramValue}`
        }).join('')
        : ''}' \
-H 'Authorization: ${props.apiKey}' \
-H 'Content-Type: ${props.contentType ? props.contentType : "application/json"}' \
${props.payload ? `-d '${JSON.stringify(props.payload, null, 2)}'` : ''} \
${props.file ? `-F 'asset=@/path/to/your/${props.file.name};filename=${props.file.name}'` : ''} \
`;
  };

  const generatePythonCode = () => {
    return (`import requests
import json
url = f"${props.url}"
headers = {
  "Authorization": "${props.apiKey}",
  "Content-Type": "${props.contentType ? props.contentType : "application/json"}"
}
${props.params ? `params = { ${props.params
  ? Object.keys(props.params)?.map((param, index) => {
    const paramValue = getParams(props.params[param]);
    return `"${param}":"${paramValue}"`
  })
  : ''} }` : ''}
${props.file ? `
file_path = 'path/to/your/${props.file.name}'
with open(file_path, 'rb') as file:
  files = {'asset': file}
  ` : ''}
${props.payload ? `payload = json.dumps(${JSON.stringify(props.payload, null, 2)})` : ''}
response = requests.${props.method}(url, headers=headers${props.params ? ', params=params' : ''}${props.payload ? ', data=payload' : ''}${props.file ? ', files=files' : ''})
print(response.json())`).replace(/[ \t]+$/gm, '').replace(/^\s*[\r\n]/gm, '');
  };

  const generateNodeJsCode = () => {
    return (`
const axios = require('axios');
const url = '${props.url}';
const headers = {
  "Authorization": "${props.apiKey}",
  "Content-Type": "${props.contentType || "application/json"}"
};
${props.params ? `const params = { ${props.params
  ? Object.keys(props.params)?.map((param, index) => {
    const paramValue = getParams(props.params[param]);
    return `${param}: '${paramValue}'`
  })
  : ''} };` : ''}
${props.file ? `
const formData = new FormData();
formData.append('asset', file, file.name);
` : ''}
${props.payload ? `
const data = ${JSON.stringify(props.payload, null, 2)};
` : ''}
axios.${props.method}(
  url,
  ${props.file ? 'formData,' : ''}
  ${props.payload ? 'data,' : ''}
  { headers${props.params ? ', params' : ''} }
)
  .then(response => console.log(response.data))
  .catch(error => console.error(error));
`).replace(/[ \t]+$/gm, '').replace(/^\s*[\r\n]/gm, '');
  };

  return (
    <div className="code-snippets">
      <Title level={3}>Code Snippets</Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="cURL" key="1">
          <CodeViewer code={generateCurlCommand()} language="cURL" />
        </TabPane>
        <TabPane tab="Python (requests)" key="2">
          <CodeViewer code={generatePythonCode()} language="Python" />
        </TabPane>
        <TabPane tab="Node.js (axios)" key="3">
          <CodeViewer code={generateNodeJsCode()} language="Node.js" />
        </TabPane>
      </Tabs>
    </div>
  )
}
