import { JsonViewer } from '@/components/json-viewer/json-viewer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from 'antd';
import axios from 'axios';
import qs from 'qs';

import { QueryCodeViewer } from '@/modules/docs/components';
import { ReportingFilter } from '@/modules/reporting/components';
import { reportingAdvertisingService } from '@/services';
import moment from 'moment';
import { FilterTool } from '@/tools';
import { useAuths } from '@/contexts';
import { useReportingTable } from '@/hooks';
import { IS_FILTERS, PARAMS_FILTERS, ResultLine, Total } from '@/modules/reporting/constant/advertising.constant';

import './search-component.css';

const { Title } = Typography;

interface AdvertisingSearchComponentProps {
  apiKey: string;
}

interface Result {
  is: string[];
  total: Total;
  results: ResultLine[];
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/report/advertising/search`;

const resultSchema = JSON.parse(`{
  "data": {
    "total": {
      "impressions": 0,
      "clicks": 0,
      "installs": 0,
      "spend": 0,
      "ecpi": 0,
      "ecpm": 0,
      "ctr": 0,
      "ir": 0
    },
    "results": [
      {
        "impressions": 0,
        "clicks": 0,
        "installs": 0,
        "spend": 0,
        "ecpi": 0,
        "ecpm": 0,
        "ctr": 0,
        "ir": 0,
        "id": 0,
        "applicationKey": "",
        "campaignKey": "",
        "countryCode": "",
        "date": "",
        "hourOfDay": "",
        "platform": ""
      }
    ]
  },
  "date": 0
}`);

export const SearchComponent: React.FC<AdvertisingSearchComponentProps> = ({ apiKey }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<any>();
  const { permissions } = useAuths();

  const {
    canSeeSourceAdv,
    canSeePlacementTypeAdv,
    canSeeAdAdv,
  } = useMemo(() => ({
    canSeeSourceAdv: FilterTool.canAccess('advertising/reports/filters/source', permissions, false),
    canSeePlacementTypeAdv: FilterTool.canAccess('advertising/reports/filters/placement_type', permissions, false),
    canSeeAdAdv: FilterTool.canAccess('advertising/reports/filters/ad', permissions, false),
  }), [permissions]);

  const getParams = useCallback((stringify: boolean, pDates: [moment.Moment, moment.Moment], pFilters: Filters, params: any = {}) => {
    const b = {
      ...(params || {}),
      dateStart: moment(pDates[0]).format("YYYY-MM-DD"),
      dateEnd: moment(pDates[1]).format("YYYY-MM-DD"),
      granularity: pFilters?.isGranularity ? pFilters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
      hours: FilterTool.getParamsFilter(pFilters?.hours),
      countries: FilterTool.getParamsFilter(pFilters?.countries),
      ...(canSeeSourceAdv ? { sources: FilterTool.getParamsFilter(pFilters?.sources) } : {}),
      platforms: FilterTool.getParamsFilter(pFilters?.platforms),
      ...(canSeePlacementTypeAdv ? { placementTypes: FilterTool.getParamsFilter(pFilters?.placementTypes) } : {}),
      applications: FilterTool.getParamsFilter(pFilters?.applications),
      campaigns: FilterTool.getParamsFilter(pFilters?.campaigns),
      is: FilterTool.getParamsIs(IS_FILTERS, pFilters),
    };
    if (stringify) {
      return qs.stringify(b, { encode: false });
    }
    return b;
  }, [canSeePlacementTypeAdv, canSeeSourceAdv]);

  const initFCItemStates = useCallback(({ data, search }: {
    data: Filters;
    search: URLSearchParams;
  }) => {
    if (canSeeSourceAdv) {
      PARAMS_FILTERS.push('sources');
    }
    if (canSeePlacementTypeAdv) {
      PARAMS_FILTERS.push('placementTypes');
    }
    FilterTool.initFCItemStates({ paramsFilters: PARAMS_FILTERS, isFilters: IS_FILTERS, data, search });
  }, [canSeePlacementTypeAdv, canSeeSourceAdv]);

  const { filters, dates, onSetFilters } = useReportingTable<Result, ResultLine>({
    service: reportingAdvertisingService,
    getParams,
    isFilters: IS_FILTERS,
    paramsFilters: PARAMS_FILTERS,
    initFCItemStates,
  });

  const filterProps: FilterPropType[] = useMemo(
    () => [
      { key: 'granularities', itemKey: 'granularities', isKey: 'isGranularity', label: 'Granularities', show: true },
      { key: 'hours', itemKey: 'hours', isKey: 'isHourOfDay', label: 'Hours', mode: 'multiple' as const, show: true },
      { key: 'countries', itemKey: 'countries', isKey: 'isCountry', label: 'Countries', mode: 'multiple' as const, show: true },
      { key: 'sources', itemKey: 'sources', isKey: 'isSource', label: 'Sources', mode: 'multiple' as const, show: canSeeSourceAdv },
      { key: 'platforms', itemKey: 'platforms', isKey: 'isPlatform', label: 'Platforms', mode: 'multiple' as const, show: true },
      { key: 'applications', itemKey: 'applications', isKey: 'isApplication', label: 'Applications', mode: 'multiple' as const, show: true },
      { key: 'campaigns', itemKey: 'campaigns', isKey: 'isCampaign', label: 'Campaigns', mode: 'multiple' as const, show: true },
      { key: 'placementTypes', itemKey: 'placementTypes', isKey: 'isPlacementType', label: 'Placement Types', mode: 'multiple' as const, show: canSeePlacementTypeAdv },
      { key: 'ads', itemKey: 'ads', isKey: 'isAd', label: 'Ads', disabledSelect: true, show: canSeeAdAdv },
    ].map((f) => ({ ...f, filters: filters, onSetFilters: onSetFilters })),
    [canSeeAdAdv, canSeePlacementTypeAdv, canSeeSourceAdv, filters, onSetFilters],
  );

  useEffect(() => {
    const paramsObject = getParams(true, dates, filters).split('&').reduce((acc, param) => {
      const [key, value] = param.split('=');
      acc[key] = value.split(',');
      return acc;
    }, {});
    setParams(paramsObject)
  }, [dates, filters, getParams])

  const fetchAdvertisingSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}?${getParams(true, dates, filters)}`, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="search-component">
      <Title level={3}>API Documentation: Get Advertising search</Title>
      <ReportingFilter
        datePresets={['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date'] as const}
        dates={dates}
        filterProps={filterProps}
        loading={isLoading}
        onDatesChange={() => { }}
      />

      <Button type="primary" onClick={fetchAdvertisingSearch} loading={isLoading}>
        Fetch Advertising search
      </Button>


      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={baseUrl} apiKey={apiKey} method='get' params={params} />
    </div>
  );
};
