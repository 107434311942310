import { useApps } from '@/modules/apps/pages/apps/use-apps';
import { Link } from 'react-router-dom';
import { AppListingComponent, AppViewComponent, AppAddComponent, AppEditComponent } from './components';
import { ReactNode, useEffect, useState } from "react";
import { UnorderedListOutlined, PlusOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Alert } from 'antd';

import { YcTabs, YcTitle } from '@/components';
import { profileService } from '@/services';

import './docs-manage-apps.page.scss';

export function DocsManageAppsPage() {
  const [tab, _setTab] = useState("listing");
  const [managerKey, setManagerKey] = useState("");
  const { displayApps } = useApps();

  useEffect(() => {
    profileService.viewManagerOrReportKey("managerKey").then((key) => {
      setManagerKey(key || '');
    });
  }, []);

  const alertMessage = (): ReactNode => {
    return <Alert message="⚠️ WARNING! All API requests made using this documentation will interact directly with the production data ⚠️" type="error" />
  }

  return (
    <div id='docs-manage-apps'>
      <YcTitle label="Docs > Manage Applications"></YcTitle>
      <p>
        In this page you can manage applications
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "listing", label: "Listing", icon: <UnorderedListOutlined /> },
        { key: "view", label: "View", icon: <EyeOutlined /> },
        { key: "add", label: "Add", icon: <PlusOutlined /> },
        { key: "edit", label: "Edit", icon: <EditOutlined /> },
      ]} />
      <div className="doc-container">
        {managerKey ?
          <>
            {tab === "listing" &&
              <AppListingComponent apiKey={managerKey} />
            }
            {tab === "view" &&
              (displayApps.length > 0 ? <AppViewComponent apiKey={managerKey} allApps={displayApps} /> : <div>Create an app before testing this</div>)
            }
            {tab === "add" &&
              <>
                {alertMessage()}
                <AppAddComponent apiKey={managerKey} />
              </>
            }
            {tab === "edit" &&
              <>
                {alertMessage()}
                {(displayApps.length > 0 ? <AppEditComponent apiKey={managerKey} allApps={displayApps} /> : <div>Create an app before testing this</div>)}
              </>
            }
          </> : <div>You need to generate an api key <Link to='/profile'>here</Link> to use this documentation</div>
        }
      </div>
    </div>
  );
}
