import { ReactNode, useEffect, useState } from "react";
import { VideoCameraOutlined, FileImageOutlined, GifOutlined, Html5Outlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Alert } from "antd";

import { YcTabs, YcTitle } from "../../../../components";
import { profileService } from "../../../../services/profile.service";
import {
  CreativeUploadAssetComponent,
} from "./components";

import './docs-manage-creatives.page.scss';


export function DocsManageCreativesPage() {
  const [tab, _setTab] = useState("video");
  const [managerKey, setManagerKey] = useState("");

  useEffect(() => {
    profileService.viewManagerOrReportKey("managerKey").then((key) => {
      setManagerKey(key || '');
    });
  }, []);

  const alertMessage = (): ReactNode => {
    return <Alert message="⚠️ WARNING! All API requests made using this documentation will interact directly with the production data ⚠️" type="error" />
  }

  return (
    <div id='docs-manage-creatives'>
      <YcTitle label="Docs > Manage Creatives"></YcTitle>
      <p>
        In this page you can manage creatives.<br /><br />
        For all upload routes, you need to send exactly 1 file with FormData in 'asset' key.<br />
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "video", label: "Video", icon: <VideoCameraOutlined /> },
        { key: "image", label: "Image", icon: <FileImageOutlined /> },
        { key: "endcard", label: "Endcard", icon: <GifOutlined /> },
        { key: "playable", label: "Playable", icon: <Html5Outlined /> },
        { key: "banner", label: "Banner", icon: <FileImageOutlined /> },
      ]} />
      <div className="doc-container">
        {!!managerKey ?
          <>
            {alertMessage()}
            {tab === "video" &&
              <CreativeUploadAssetComponent assetName={'Video'} endRoute={'upload-video'} apiKey={managerKey} />
            }
            {tab === "image" &&
              <CreativeUploadAssetComponent assetName={'Image'} endRoute={'upload-image'} apiKey={managerKey} />
            }
            {tab === "endcard" &&
              <CreativeUploadAssetComponent assetName={'Endcard'} endRoute={'upload-endcard'} apiKey={managerKey} />
            }
            {tab === "playable" &&
              <CreativeUploadAssetComponent assetName={'Playable'} endRoute={'upload-playable'} apiKey={managerKey} />
            }
            {tab === "banner" &&
              <CreativeUploadAssetComponent assetName={'Banner'} endRoute={'upload-banner'} apiKey={managerKey} />
            }
          </> : <div>You need to generate an api key <Link to='/profile'>here</Link> to use this documentation</div>
        }
      </div>
    </div>
  );
}