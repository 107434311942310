import { QueryCodeViewer } from '@/modules/docs/components';
import React, { useMemo, useState } from 'react';
import { Button, Form, Input, message, Select, Tabs, Typography } from 'antd';
import axios from 'axios';

import { JsonViewer } from '@/components';
import { MApp, MCampaign, MCampaignAsset, MCampaignCountry } from '@/modeles';
import { CampaignBanners, CampaignCountries, CampaignEndcards, CampaignImages, CampaignPlayables, CampaignVideos } from '@/modules/advertising/components';

import './campaign-add-component.css';

const { Title } = Typography;

interface CampaignAdd {
  name: string;
  key: string;
  applicationKey: string;
  budget: number;
  trackingLinkImpression: string;
  trackingLinkClick: string;
  countries: MCampaignCountry[]
  Videos: MCampaignAsset[];
  Images: MCampaignAsset[];
  Endcards: MCampaignAsset[];
  Banners: MCampaignAsset[];
  Playables: MCampaignAsset[];
}

interface CampaignAddComponentProps {
  apiKey: string;
  displayApps: MApp[],
}

function handleFormValidation(campaign?: MCampaign) {
  if (!campaign) {
    message.warning('Form not initialized.');
    return null;
  }

  return campaign;
}

function extractCampaignAddParams(campaign: MCampaign): CampaignAdd {
  return {
    name: campaign.name,
    key: campaign.key,
    applicationKey: campaign.applicationKey ?? "",
    budget: campaign.budget ?? 0,
    trackingLinkImpression: campaign.trackingLinkImpression,
    trackingLinkClick: campaign.trackingLinkClick,
    countries: campaign.countries,
    Videos: campaign.Videos,
    Images: campaign.Images,
    Endcards: campaign.Endcards,
    Banners: campaign.Banners,
    Playables: campaign.Playables,
  };
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/campaigns/add`;

const resultSchema = JSON.parse(`{
  "data": {
    "name": "",
    "key": "",
    "applicationKey": "",
    "budget": 0,
    "spendDay": 0,
    "isValide": true,
    "isActive": true,
    "createdAt": "2024-11-19",
    "countries": [
      {
        "key": "",
        "cpi": 0,
        "isActive": true
      }
    ],
    "trackingLinkImpression": "",
    "trackingLinkClick": "",
    "Application": {
      "key": "",
      "name": "",
      "platform": "ios|android",
      "bundleId": "",
      "appleId": "",
      "isCallbackCreated": true,
      "storeUrl": "",
      "Icon": {
        "url": ""
      },
      "nbCampaigns": 0,
      "nbCampaignsActive": 0,
      "nbPlacements": 0,
      "nbPlacementsActive": 0
    },
    "Account": {
      "name": ""
    },
    "Videos": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Images": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Endcards": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Banners": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Playables": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ]
  },
  "date": 0
}`);

export const CampaignAddComponent: React.FC<CampaignAddComponentProps> = ({ apiKey, displayApps }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaign, _setCampaign] = useState<MCampaign>(new MCampaign());

  const activeVideos = useMemo(() => campaign.Videos.filter(ad => ad.isActive).length, [campaign.Videos]);
  const activeImages = useMemo(() => campaign.Images.filter(ad => ad.isActive).length, [campaign.Images]);
  const activeEndcards = useMemo(() => campaign.Endcards.filter(ad => ad.isActive).length, [campaign.Endcards]);
  const activePlayables = useMemo(() => campaign.Playables.filter(ad => ad.isActive).length, [campaign.Playables]);
  const activeBanners = useMemo(() => campaign.Banners.filter(ad => ad.isActive).length, [campaign.Banners]);

  const fetchCampaign = async () => {
    const campaignToCreate = handleFormValidation(campaign);

    if (!campaignToCreate) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(baseUrl, {
        name: campaignToCreate.name,
        key: campaignToCreate.key,
        applicationKey: campaignToCreate.applicationKey,
        budget: campaignToCreate.budget,
        trackingLinkImpression: campaignToCreate.trackingLinkImpression,
        trackingLinkClick: campaignToCreate.trackingLinkClick,
      }, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="campaign-add-component">
      <Title level={3}>API Documentation: Create Campaign</Title>
      <Form layout="vertical" onValuesChange={(v) => _setCampaign({ ...campaign, ...v })}>
        <div className='row'>
          <div className='col-3'>
            <Form.Item label="Name" name='name'>
              <Input type='text' placeholder='' />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Application" name='applicationKey'>
              <Select
              showSearch
              placeholder="Choose an app"
              options={displayApps.map((app) => ({ value: app.key, label: app.name }))} />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Key" name='key'>
              <Input type='text' placeholder='' />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Daily budget" name='budget'>
              <Input type='number' placeholder='' />
            </Form.Item>
          </div>
          <div className='col-6'>
            <Form.Item label="Tracking Link (Impression)" name='trackingLinkImpression'>
              <Input type='text' placeholder='' />
            </Form.Item>
          </div>
          <div className='col-6'>
            <Form.Item label="Tracking Link (Click)" name='trackingLinkClick'>
              <Input type='text' placeholder='' />
            </Form.Item>
          </div>
          <div className='col-12 block'>
            <CampaignCountries campaign={campaign} _setCampaign={_setCampaign} />
          </div>
          <div className='col-12 block'>
            <Tabs defaultActiveKey="1" >
              <Tabs.TabPane tab={"Videos (" + activeVideos + " / " + campaign.Videos.length + ")"} key="1">
                <CampaignVideos campaign={campaign} _setCampaign={_setCampaign}></CampaignVideos>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Playables (" + activePlayables + " / " + campaign.Playables?.length + ")"} key="4">
                <CampaignPlayables campaign={campaign} _setCampaign={_setCampaign}></CampaignPlayables>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Images (" + activeImages + " / " + campaign.Images.length + ")"} key="2">
                <CampaignImages campaign={campaign} _setCampaign={_setCampaign}></CampaignImages>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Endcards (" + activeEndcards + " / " + campaign.Endcards.length + ")"} key="3">
                <CampaignEndcards campaign={campaign} _setCampaign={_setCampaign}></CampaignEndcards>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Banners (" + activeBanners + " / " + campaign.Banners.length + ")"} key="5">
                <CampaignBanners campaign={campaign} _setCampaign={_setCampaign}></CampaignBanners>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>

        <Form.Item>
          <Button type="primary" onClick={fetchCampaign} loading={isLoading}>
            Create Campaign
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={baseUrl} apiKey={apiKey} method='post' payload={extractCampaignAddParams(campaign)} />
    </div>
  );
};
