import React from 'react';

import { AddFilter, HavingFilter, YcColumnType } from '@/components';

function getBreakdownColumn<ResultLine>({ filters, title, dataKey, itemKey, onSetFilter, renderFunction }: {
  filters: Filters;
  title: string;
  dataKey: string;
  itemKey?: string;
  onSetFilter: ({ key, value, checked }) => void;
  renderFunction?: (value: string) => string;
}) {
  return {
    title,
    key: dataKey,
    dataIndex: dataKey,
    ycCanNotHide: true,
    ...(itemKey ? {
      render: (_text, record) => (<div>
        { renderFunction ? renderFunction(record[dataKey]) : record[dataKey] }
        <AddFilter key={itemKey} item={itemKey} value={record[dataKey]} filters={filters} onSetFilter={onSetFilter} />
      </div>)
    } : {}),
  } as YcColumnType<ResultLine>;
}

export function getStringColumn<ResultLine>(p: {
  filters: Filters;
  title: string;
  dataKey: string;
  itemKey?: string;
  onSetFilter: ({ key, value, checked }) => void;
  renderFunction?: (value: string) => string;
}) {
  return { ...getBreakdownColumn(p), ycSort: 'string' } as YcColumnType<ResultLine>;
}

export function getNumberColumn<ResultLine>(p: {
  filters: Filters;
  title: string;
  dataKey: string;
  itemKey?: string;
  onSetFilter: ({ key, value, checked }) => void;
}) {
  return { ...getBreakdownColumn(p), ycSort: 'number' } as YcColumnType<ResultLine>;
}

export function getNumericColumn<ResultLine>(p: {
  filters: Filters;
  title: string;
  dataKey: string;
  itemKey?: string;
  onSetFilter: ({ key, value, checked }) => void;
}) {
  return { ...getBreakdownColumn(p), ycSort: 'numeric' } as YcColumnType<ResultLine>;
}

function Filter<ResultLine>({ filterKey, results, _setTableData }: { results: ResultLine[], filterKey: string, _setTableData: (value: React.SetStateAction<ResultLine[]>) => void }) {
  return <HavingFilter
    onFilter={(v, c) => _setTableData(results.filter(v1 => (c(v, v1[filterKey] as number) <= 0)) || [])}
    onReset={() => _setTableData(results)}
    filterKey={filterKey}
  />;
}

export function getMetricColumn<ResultLine, T>({ title, dataKey, numberFormat, render, config, results, _setTableData }: {
  title: string;
  dataKey: string;
  numberFormat?: (nb: number) => string | number | undefined;
  config?: T;
  render?: YcColumnType<ResultLine>['render'];
  results: ResultLine[];
  _setTableData: (value: React.SetStateAction<ResultLine[]>) => void;
}) {
  if (!numberFormat && !render) {
    return {};
  }
  return {
    title, dataIndex: dataKey, key: dataKey, ycSort: 'number' as const,
    render: render || ((_text, record) => numberFormat!(record[dataKey] as number)),
    filterDropdown: <Filter filterKey={dataKey} results={results} _setTableData={_setTableData} />,
    ...(config || {}),
  } as YcColumnType<ResultLine>;
}
