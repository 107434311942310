import React, { useCallback, useEffect, useState } from 'react';
import { campaignsService } from '@/services';

export interface CapContextType {
  hasCap: boolean;
  refreshCap: () => void;
}

const CapContext = React.createContext<CapContextType | null>(null);

export function CapContextProvider({ children }: { children: React.ReactNode }) {
  const [hasCap, _setHasCap] = useState<boolean>(false);

  const refreshCap = useCallback(() => {
    campaignsService.isOverCapToday().then((data) => {
      _setHasCap(data);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    refreshCap();
  }, []);

  const value: CapContextType = {
    hasCap,
    refreshCap,
  };

  return (
    <CapContext.Provider value={value}>
      {children}
    </CapContext.Provider>
  );
}

export function useCap() {
  const context = React.useContext(CapContext);
  if (!context) throw new Error('No CapContext provider found!');
  return context;
}
