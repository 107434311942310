import { JsonViewer } from '@/components/json-viewer/json-viewer';
import React, { useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import axios from 'axios';

import { QueryCodeViewer } from '@/modules/docs/components';

import './campaign-listing-component.css';

const { Title } = Typography;

interface CampaignListingComponentProps {
  apiKey: string;
}

interface listingParams {
  fields?: string[];
  applicationFields?: string[];
}

const fieldOptions = [
  "applicationKey", "budget", "countries", "spendDay", "isValide", "isActive", "createdAt",
  "trackingLinkImpression", "trackingLinkClick",
  "Videos", "Images", "Endcards", "Playables", "Banners"
];
const applicationFieldOptions = ["platform", "bundleId", "appleId", "storeUrl", "icon"];
const baseUrl = `${process.env.REACT_APP_API_URL}/api/campaigns/listing`;

const resultSchema = JSON.parse(`{
  "data": [
    {
      "name": "",
      "key": "",
      "applicationKey": "",
      "budget": 0,
      "spendDay": 0,
      "isValide": true,
      "isActive": true,
      "createdAt": "2024-11-19",
      "countries": [
        {
          "key": "",
          "cpi": 0,
          "isActive": true
        }
      ],
      "trackingLinkImpression": "",
      "trackingLinkClick": "",
      "Application": {
        "key": "",
        "name": "",
        "platform": "ios|android",
        "bundleId": "",
        "appleId": "",
        "isCallbackCreated": true,
        "storeUrl": "",
        "Icon": {
          "url": ""
        },
        "nbCampaigns": 0,
        "nbCampaignsActive": 0,
        "nbPlacements": 0,
        "nbPlacementsActive": 0
      },
      "Account": {
        "name": ""
      },
      "Videos": [
        {
          "id": 0,
          "isActive": true,
          "isValide": true,
          "createdAt": "2024-11-19",
          "Document": {
            "id": 0,
            "url": "",
            "originalFileName": ""
          }
        }
      ],
      "Images": [
        {
          "id": 0,
          "isActive": true,
          "isValide": true,
          "createdAt": "2024-11-19",
          "Document": {
            "id": 0,
            "url": "",
            "originalFileName": ""
          }
        }
      ],
      "Endcards": [
        {
          "id": 0,
          "isActive": true,
          "isValide": true,
          "createdAt": "2024-11-19",
          "Document": {
            "id": 0,
            "url": "",
            "originalFileName": ""
          }
        }
      ],
      "Banners": [
        {
          "id": 0,
          "isActive": true,
          "isValide": true,
          "createdAt": "2024-11-19",
          "Document": {
            "id": 0,
            "url": "",
            "originalFileName": ""
          }
        }
      ],
      "Playables": [
        {
          "id": 0,
          "isActive": true,
          "isValide": true,
          "createdAt": "2024-11-19",
          "Document": {
            "id": 0,
            "url": "",
            "originalFileName": ""
          }
        }
      ]
    }
  ],
  "date": 0
}`);

export const CampaignListingComponent: React.FC<CampaignListingComponentProps> = ({ apiKey }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<listingParams>();

  const onChangeParams = (values: listingParams) => {
    const updatedParams = { ...params };
    Object.keys(values)?.forEach((property) => {
      if (values[property] === null || values[property] === undefined || values[property].length <= 0) {
        delete updatedParams[property];
        setParams(updatedParams);
      } else {
        setParams({ ...params, [property]: values[property] });
      }
    })
  };

  const fetchCampaignListing = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(baseUrl, {
        headers: { Authorization: apiKey },
        params: (params?.fields?.length || params?.applicationFields?.length) ? {
          ...(params?.fields?.length ? { fields: params.fields.join(',') } : {}),
          ...(params?.applicationFields?.length ? { applicationFields: params.applicationFields.join(',') } : {}),
        } : undefined,
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="campaign-listing-component">
      <Title level={3}>API Documentation: Get Campaign Listings</Title>
      <Form layout="vertical" onValuesChange={(values) => { onChangeParams(values); }}>
        <Form.Item label="Fields" name='fields'>
          <Select
            mode="multiple"
            placeholder="Select fields to include in the response"
            value={params?.fields}
            options={fieldOptions.map(field => ({ value: field }))}
          />
        </Form.Item>
        <Form.Item label="Application Fields" name='applicationFields'>
          <Select
            mode="multiple"
            placeholder="Select application fields to include in the response"
            value={params?.applicationFields}
            options={applicationFieldOptions.map(field => ({ value: field }))}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetchCampaignListing} loading={isLoading}>
            Fetch Campaign Listings
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={baseUrl} apiKey={apiKey} method='get' params={params} />
    </div>
  );
};
