import { QueryCodeViewer } from '@/modules/docs/components';
import React, { useState } from 'react';
import { Button, Form, message, Typography, Upload } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { UploadOutlined } from '@ant-design/icons';

import { JsonViewer } from '@/components';

import './creative-upload-asset-component.css';

const { Title } = Typography;

interface CreativeUploadAssetComponentProps {
  assetName: string;
  endRoute: string;
  apiKey: string;
}

function handleFormValidation(asset?: any) {
  if (!asset) {
    message.warning('Form not initialized.');
    return null;
  }

  return asset;
}

const resultSchema = JSON.parse(`{
  "data": {
    "id": 0,
    "originalFileName": "",
    "url": ""
  },
  "date": 0
}`);

export const CreativeUploadAssetComponent: React.FC<CreativeUploadAssetComponentProps> = ({ assetName, endRoute, apiKey }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [asset, setAsset] = useState<File | null>(null);

  const handleUploadFileChange = (info: any) => {
    if(info.fileList[0]){
      const file = info.fileList[0].originFileObj;
      if (file) {
        setAsset(file);
      }  
    } else {
      setAsset(null);
    }
  };

  const fetchAsset = async () => {
    const assetToUpload = handleFormValidation(asset);

    if (!assetToUpload || !asset) {
      return;
    }

    const formData = new FormData();
    formData.append('asset', asset, asset.name);

    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/campaigns/${endRoute}`,
        formData,
        {
          headers: {
            Authorization: apiKey,
            'Content-Type': 'multipart/form-data',
          },
        });
      setApiResponse(response.data);
    } catch (error) {
      const res: AxiosResponse | undefined = (error as AxiosError).response;
      message.error(res?.data.message);
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="creative-upload-asset-component">
      <Title level={3}>API Documentation: Upload {assetName}</Title>
      <Form layout="vertical">
        <div className='row'>
          <div className='col-3'>
            <Form.Item label="Upload your Playable" name='name'>
              <Upload
                listType="picture-card"
                beforeUpload={() => false}
                onChange={handleUploadFileChange}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <Button type="primary" onClick={fetchAsset} loading={isLoading}>
            Upload {assetName}
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer
        url={`${process.env.REACT_APP_API_URL}/api/campaigns/${endRoute}`}
        apiKey={apiKey}
        method='post'
        file={asset}
        contentType='multipart/form-data' />
    </div>
  );
};
