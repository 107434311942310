import React, { useState } from 'react';
import { Button, Form, Input, message, Typography, Switch, Select } from 'antd';
import axios from 'axios';

import { JsonViewer } from '@/components';
import { QueryCodeViewer } from '@/modules/docs/components';

import './placement-edit-component.css';
import { MPlacement } from '@/modeles';

const { Title } = Typography;

interface PlacementEdit {
  key?: string;
  name?: string;
  isActive?: boolean;
}

interface PlacementEditComponentProps {
  apiKey: string;
  displayPlacements: MPlacement[];
}

function handleFormValidation(placement?: PlacementEdit) {
  if (!placement) {
    message.warning('Form not initialized.');
    return null;
  }

  return placement;
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/placements/edit`;

const resultSchema = JSON.parse(`{
  "data": {
    "key": "",
    "name": "",
    "type": "interstitial|rewarded|banner",
    "isActive": true,
    "applicationKey": "",
    "Application": {
      "key": "",
      "name": "",
      "platform": "ios|android",
      "bundleId": "",
      "appleId": "",
      "isCallbackCreated": true,
      "storeUrl": "",
      "Icon": {
        "url": ""
      },
      "nbCampaigns": 0,
      "nbCampaignsActive": 0,
      "nbPlacements": 0,
      "nbPlacementsActive": 0
    }
  },
  "date": 0
}`);

export const PlacementEditComponent: React.FC<PlacementEditComponentProps> = ({ apiKey, displayPlacements }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [placement, setPlacement] = useState<PlacementEdit>(displayPlacements[0]);

  const fetchPlacement = async () => {
    const placementToCreate = handleFormValidation(placement);

    if (!placementToCreate) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/${placement?.key}`, {
        name: placementToCreate.name,
        isActive: placementToCreate.isActive,
      }, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanPayload = (values: any) => {
    const updatedPlacement = { ...placement };
    Object.keys(values).forEach((property) => {
      if (values[property] === null || values[property] === undefined || values[property] === '') {
        delete updatedPlacement[property];
        setPlacement(updatedPlacement);
      } else {
        setPlacement({ ...placement, [property]: values[property] });
      }
    })
  };

  return (
    <div className="placement-edit-component">
      <Title level={3}>API Documentation: Edit Placement</Title>
      <Form layout="vertical" onValuesChange={(values) => { cleanPayload(values); }}>
        <div className='row'>
          <div className='col-3'>
            <Form.Item label="Key" name='key'>
              <Select
                placeholder="Choose a placement"
                showSearch
                value={placement?.key}
                options={displayPlacements.map((placement) => ({ value: placement.key, label: `${placement.Application?.key} - ${placement.name}` }))}
              />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Name" name='name'>
              <Input type='text' placeholder='name' />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Is Active" name='isActive'>
              <Switch />
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <Button type="primary" onClick={fetchPlacement} loading={isLoading}>
            Edit Placement
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={`${baseUrl}/${placement?.key}`} apiKey={apiKey} method='post' payload={placement} />
    </div>
  );
};
