import { Button, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { accountsService_SA } from '@/services';
import { MAccount, MUser } from '@/modeles';

import './accounts.page.scss';
import { Link } from 'react-router-dom';
import { useAuths, useCap } from '@/contexts';
import { SearchBar, YcTitle } from '@/components';
import { FilterTool } from '@/tools';
import { usePageTitle } from '@/hooks';

export function SAAccountsPage() {
  usePageTitle('Accounts');

  const [accounts, _setAccounts] = useState<MAccount[]>([]);
  const [displayAccounts, _setDisplayAccounts] = useState<MAccount[]>([]);
  const { isSuperAdmin, loginAs: doLoginAs } = useAuths();
  const { refreshCap } = useCap();

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const { value } = event.target;

    _setDisplayAccounts(accounts.filter((a) => {
      let checkSearch = true;
      if (value) {
        const checkName = a.name.toLowerCase().includes(value.toLowerCase());
        const checkEmails = !!(a.Users?.some((u) => u.email.toLowerCase().includes(value.toLowerCase())));

        checkSearch = checkName || checkEmails;
      }

      return checkSearch;
    }));
  }, [accounts]);

  useEffect(() => {
    accountsService_SA.listing().then((data) => {
      if (data) {
        _setAccounts(data);
        _setDisplayAccounts(data);
      }
    });
  }, []);

  const onUserActivate = async (user: Partial<MUser> & { id: number; }) => {
    await accountsService_SA.userActivate(user.id);
    user.isActive = true;
    _setAccounts([...accounts]);
  }

  const onUserDeactivate = async (user: Partial<MUser> & { id: number; }) => {
    await accountsService_SA.userDeactivate(user.id);
    user.isActive = false;
    _setAccounts([...accounts]);
  }

  const onAccountActivate = async (account: Partial<MAccount> & { id: number; }) => {
    await accountsService_SA.accountActivate(account.id);
    account.isActive = true;
    _setAccounts([...accounts]);
  }

  const onAccountDeactivate = async (account: Partial<MAccount> & { id: number; }) => {
    await accountsService_SA.accountDeactivate(account.id);
    account.isActive = false;
    _setAccounts([...accounts]);
  }

  const onAccountValidate = async (account: Partial<MAccount> & { id: number; }) => {
    await accountsService_SA.accountValidate(account.id);
    account.isValide = true;
    _setAccounts([...accounts]);
  }

  const onAccountInvalidate = async (account: Partial<MAccount> & { id: number; }) => {
    await accountsService_SA.accountDevalidate(account.id);
    account.isValide = false;
    account.isActive = false;
    _setAccounts([...accounts]);
  }

  const loginAs = async (userId: number) => {
    await doLoginAs(userId);
    refreshCap();
  }

  return (
    <div id='admin-account'>
      <YcTitle label="Accounts" ></YcTitle>
      <SearchBar onSearch={onSearch} placeholder={"Filter account name or email"} />
      <Table dataSource={displayAccounts} columns={[
        { title: 'Id', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id - b.id },
        {
          title: 'Name', key: 'name',
          render: (_: string, account: MAccount) => <Link to={"/superadmin/accounts/account/" + account.id}>{account.name}</Link>,
          sorter: (a, b) => a.name?.localeCompare(b.name || '') || -1
        },
        {
          title: 'Credit Line', key: 'creditLine',
          render: (_: string, account: MAccount) => account.creditLine?.toLocaleString('en'),
          sorter: (a, b) => (a.creditLine || 0) - (b.creditLine || 0)
        },
        {
          title: 'Users', key: 'users',
          render: (_: string, record: MAccount) =>
            <>
              {
                record.Users?.map((user: MUser) =>
                  <div key={user.id}>
                    <Link to={"/users/user/" + user.id}>{user.email}</Link>
                    &nbsp;
                    {user.isAdmin && <b>Admin</b>}
                    &nbsp;
                    {user.isActive &&
                      <Button type='link' onClick={() => (onUserDeactivate(user))}>
                        <PlusCircleOutlined style={{ color: 'green' }} />
                      </Button>
                    }
                    {!user.isActive &&
                      <Button type='link' onClick={() => (onUserActivate(user))}>
                        <PlusCircleOutlined style={{ color: 'red' }} />
                      </Button>
                    }
                    &nbsp;
                    <Button type='link' onClick={() => loginAs(user.id)}>Login as</Button>
                  </div>
                )
              }
              {isSuperAdmin() && <Link to={"/users/user?accountId=" + record.id}>+ Add user</Link>}
            </>,
          sorter: (a, b) => (a.Users?.length || 0) - (b.Users?.length || 0)
        },
        {
          title: 'Active', key: 'isActive',
          render: (_: string, record: MAccount) =>
            <>
              {record.isActive &&
                <Button type='link' onClick={() => (onAccountDeactivate(record))}>
                  <PlusCircleOutlined style={{ color: 'green' }} />
                </Button>
              }
              {!record.isActive &&
                <Button type="link" onClick={() => (onAccountActivate(record))}>
                  <PlusCircleOutlined style={{ color: 'red' }} />
                </Button>
              }
            </>,
          filters: FilterTool.BOOL_filters('Active', 'Inactive'),
          onFilter: FilterTool.BOOL_onFilter('isActive'),
        },
        {
          title: 'Validate', key: 'isValid',
          render: (_: string, record: MAccount) =>
            <>
              {record.isValide === null &&
                <>
                  <Button type="link" onClick={() => (onAccountValidate(record))}>
                    <PlusCircleOutlined style={{ color: 'green' }} />
                  </Button>
                  &nbsp;
                  <Button type="link" onClick={() => (onAccountInvalidate(record))}>
                    <PlusCircleOutlined style={{ color: 'red' }} />
                  </Button>
                </>
              }
              {record.isValide === true &&
                <Button type="link" onClick={() => (onAccountInvalidate(record))}>
                  <PlusCircleOutlined style={{ color: 'green' }} />
                </Button>
              }
              {record.isValide === false &&
                <Button type="link" onClick={() => (onAccountValidate(record))}>
                  <PlusCircleOutlined style={{ color: 'red' }} />
                </Button>
              }
            </>,
          filters: FilterTool.BOOL_filters('Valid', 'Invalid', 'To validate'),
          onFilter: FilterTool.BOOL_onFilter('isValid'),
        },
      ]} rowKey="id" />
    </div>
  );
}
