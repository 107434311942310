import { MCampaign, MCampaignAsset, MCampaignCountry } from '@/modeles';
import React, { useMemo, useState } from 'react';
import { Button, Form, Input, Select, Tabs, Typography } from 'antd';
import axios from 'axios';

import { JsonViewer } from '@/components';
import { QueryCodeViewer } from '@/modules/docs/components';
import { CampaignBanners, CampaignCountries, CampaignEndcards, CampaignImages, CampaignPlayables, CampaignVideos } from '@/modules/advertising/components';
import { campaignsService } from '@/services';

import './campaign-edit-component.css';

const { Title } = Typography;

interface CampaignEdit {
  name: string;
  key: string;
  budget: number;
  isActive: boolean;
  countries: MCampaignCountry[]
  Videos: MCampaignAsset[];
  Images: MCampaignAsset[];
  Endcards: MCampaignAsset[];
  Banners: MCampaignAsset[];
  Playables: MCampaignAsset[];
}

interface CampaignEditComponentProps {
  apiKey: string;
  allCampaigns: MCampaign[];
}

const baseUrl = `${process.env.REACT_APP_API_URL}/api/campaigns/edit`;

const resultSchema = JSON.parse(`{
  "data": {
    "name": "",
    "key": "",
    "applicationKey": "",
    "budget": 0,
    "spendDay": 0,
    "isValide": true,
    "isActive": true,
    "createdAt": "2024-11-19",
    "countries": [
      {
        "key": "",
        "cpi": 0,
        "isActive": true
      }
    ],
    "trackingLinkImpression": "",
    "trackingLinkClick": "",
    "Application": {
      "key": "",
      "name": "",
      "platform": "ios|android",
      "bundleId": "",
      "appleId": "",
      "isCallbackCreated": true,
      "storeUrl": "",
      "Icon": {
        "url": ""
      },
      "nbCampaigns": 0,
      "nbCampaignsActive": 0,
      "nbPlacements": 0,
      "nbPlacementsActive": 0
    },
    "Account": {
      "name": ""
    },
    "Videos": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Images": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Endcards": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Banners": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ],
    "Playables": [
      {
        "id": 0,
        "isActive": true,
        "isValide": true,
        "createdAt": "2024-11-19",
        "Document": {
          "id": 0,
          "url": "",
          "originalFileName": ""
        }
      }
    ]
  },
  "date": 0
}`);

function extractCampaignEditParams(campaign: MCampaign): CampaignEdit {
  return {
    name: campaign.name,
    key: campaign.key,
    budget: campaign.budget ?? 0,
    isActive: campaign.isActive || true,
    countries: campaign.countries,
    Videos: campaign.Videos,
    Images: campaign.Images,
    Endcards: campaign.Endcards,
    Banners: campaign.Banners,
    Playables: campaign.Playables,
  };
}

export const CampaignEditComponent: React.FC<CampaignEditComponentProps> = ({ apiKey, allCampaigns }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaign, _setCampaign] = useState<MCampaign>(new MCampaign());
  const [form] = Form.useForm();

  const activeVideos = useMemo(() => campaign.Videos.filter(ad => ad.isActive).length, [campaign.Videos]);
  const activeImages = useMemo(() => campaign.Images.filter(ad => ad.isActive).length, [campaign.Images]);
  const activeEndcards = useMemo(() => campaign.Endcards.filter(ad => ad.isActive).length, [campaign.Endcards]);
  const activePlayables = useMemo(() => campaign.Playables.filter(ad => ad.isActive).length, [campaign.Playables]);
  const activeBanners = useMemo(() => campaign.Banners.filter(ad => ad.isActive).length, [campaign.Banners]);

  const fetchCampaign = async () => {

    if (!campaign) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/${campaign?.key}`, {
        name: campaign.name,
      }, {
        headers: { Authorization: apiKey },
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanPayload = (values: any) => {
    const updatedCampaign = { ...campaign as MCampaign };
    Object.keys(values).forEach((property) => {
      if (values[property] === null || values[property] === undefined || values[property] === '') {
        delete updatedCampaign[property];
        _setCampaign(updatedCampaign);
      } else {
        _setCampaign({ ...campaign as MCampaign, [property]: values[property] });
      }
    })
  };

  const loadCampaign = (key: string) => {
    campaignsService.view(key).then((data) => {
      _setCampaign(data!);
      form.resetFields();
    })
  };

  return (
    <div className="campaign-edit-component">
      <Title level={3}>API Documentation: Update Campaign</Title>
      <Form layout="vertical" onValuesChange={(values) => { cleanPayload(values); }} form={form}>
        <div className='row'>
          <div className='col-3'>
            <Form.Item label="Campaign Key" name='key' initialValue={campaign.key}>
              <Select
                showSearch
                placeholder="Select campaign key to view"
                onChange={(key) => {loadCampaign(key)}}
                options={allCampaigns.map(campaign => ({ value: campaign.key, label: campaign.name }))}
              />
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Name" name='name' initialValue={campaign.name}>
              <Input type='text' placeholder='Input campaign name'/>
            </Form.Item>
          </div>
          <div className='col-3'>
            <Form.Item label="Daily budget" name='budget' initialValue={campaign.budget}>
              <Input type='number' placeholder=''/>
            </Form.Item>
          </div>
          <div className='col-12 block'>
            <CampaignCountries campaign={campaign} _setCampaign={_setCampaign} />
          </div>
          <div className='col-12 block'>
            <Tabs defaultActiveKey="1" >
              <Tabs.TabPane tab={"Videos (" + activeVideos + " / " + campaign.Videos.length + ")"} key="1">
                <CampaignVideos campaign={campaign} _setCampaign={_setCampaign}></CampaignVideos>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Playables (" + activePlayables + " / " + campaign.Playables?.length + ")"} key="4">
                <CampaignPlayables campaign={campaign} _setCampaign={_setCampaign}></CampaignPlayables>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Images (" + activeImages + " / " + campaign.Images.length + ")"} key="2">
                <CampaignImages campaign={campaign} _setCampaign={_setCampaign}></CampaignImages>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Endcards (" + activeEndcards + " / " + campaign.Endcards.length + ")"} key="3">
                <CampaignEndcards campaign={campaign} _setCampaign={_setCampaign}></CampaignEndcards>
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Banners (" + activeBanners + " / " + campaign.Banners.length + ")"} key="5">
                <CampaignBanners campaign={campaign} _setCampaign={_setCampaign}></CampaignBanners>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" onClick={fetchCampaign} loading={isLoading}>
            Update Campaign
          </Button>
        </Form.Item>

      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={`${baseUrl}/${campaign?.key}`} apiKey={apiKey} method='post' payload={extractCampaignEditParams(campaign)} />
    </div>
  );
};
