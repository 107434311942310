export enum BidAbTests {
  CONTROL = 'control',
  TEST_DEVICE = 'test-device',
  NO_BID = 'no-bid',
  NO_LEARNING = 'no-learning',
  LEARNING = 'learning',
  LEARNING_20 = 'learning-20',

  // Testing abs
  BASIC = 'basic',
  ML = 'ml',
  ML_BASIC = 'ml-basic',
  NO_BID_FLOOR = 'no-bid-floor',
  ROI_REG_OFF = 'roi-reg-off',
  ROI_REG_10 = 'roi-reg-10',
  ROI_REG_NO_MAX = 'roi-reg-no-max',
  ROI_REG_NO_MAX_50 = 'roi-reg-no-max-50',
  DISABLE_BONUS = 'disable-bonus',
  DISABLE_BONUS_M = 'disable-bonus-m',
  DISABLE_BONUS_UA = 'disable-bonus-ua',
  LOW_ASSET_QUALITY = 'low-asset-quality',
  HIGH_ASSET_QUALITY = 'high-asset-quality',
  ORIGINAL_ASSET_QUALITY = 'original-asset-quality',
  WEBVIEW_LOAD = 'webview-load',
  SDK_LOAD = 'sdk-load',
  RAND_AD_TOP_3 = 'rand-ad-top-3',
  AD_TOP_5 = 'ad-top-5',
  AD_COMPUTE_ICON_FIRST = 'ad-compute-icon-first',
  AD_COMPUTE_TRUSTED = 'ad-compute-trusted',
  AD_NO_FILTER = 'ad-no-filter',
  AD_ICON_FILTER = 'ad-icon-filter',
  IMP_X = 'imp-x',
  DIRECT_TRACK = 'direct-track',
  S2S_TRACK = 's2s-track',
  REMOVE_BATCH_TRACK = 'remove-batch-track',
  OVERLAY = 'overlay',
  MAX_BID_IR_NO_LIMIT = 'max-bid-ir-no-limit',
  TOP_CREA_PRED = 'top-crea-pred',
  CLIENT_M_NO_MULTIPLIER = 'client-m-no-multiplier',
  WP = 'wp',
  SWP = 'swp',
  MB_0 = 'mb-0',
  MB_5 = 'mb-5',
  MB_10 = 'mb-10',
  MB_FINAL_10 = 'mb-final-10',
  WP_MIN = 'wp-min',
  WP_MAX = 'wp-max',
  ML_NEXT = 'ml-next-19',
  ML_NEXT_19B = 'ml-next-19b',
  CONTENT_RATING = 'content-rating',
  ROAS_D0_80 = 'roas-d0-80',
  LEARNING_10 = 'l-10',
  MB_OLD = 'mb-old',
  NO_BF_ALL = 'no-bf-all',
  BASIC_OP = 'basic-op',
  BASIC_SOURCE_IR = 'basic-source-ir'
}

export default BidAbTests;
