import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Tabs, TabsProps } from 'antd';

import './json-viewer.scss';

interface JsonViewerProps {
  jsonData: object | null;
  schema: object | null;
}

export const JsonViewer: React.FC<JsonViewerProps> = ({ jsonData, schema }) => {
  const [activeKeySelected, setActiveKeySelected] = useState<string>('1')

  useEffect(() => {
    if(jsonData)  {
      setActiveKeySelected('2');
    } else {
      setActiveKeySelected('1');
    }
  }, [jsonData])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Schema',
      children: <>
        {schema ? (
          <ReactJson
            src={schema}
            collapseStringsAfterLength={64}
            enableClipboard={true}
            displayDataTypes={true}
            displayObjectSize={false}
            collapsed={2}
          />
        ) : (
          <div className="no-data" />
        )}
      </>,
    },
    {
      key: '2',
      label: 'Result',
      disabled: !jsonData,
      children: <>
        {jsonData ? (
          <ReactJson
            src={jsonData}
            collapseStringsAfterLength={64}
            enableClipboard={true}
            displayDataTypes={true}
            displayObjectSize={false}
            collapsed={2}
          />
        ) : (
          <div className="no-data" />
        )}
      </>,
    },
  ];

  return (
    <div className="json-viewer">
      <Tabs
      defaultActiveKey={activeKeySelected}
      items={items}
      activeKey={activeKeySelected}
      onChange={(e) => {setActiveKeySelected(e)}}
      />
    </div>
  );
};
