import { Link } from 'react-router-dom';
import { CampaignAddComponent, CampaignEditComponent, CampaignListingComponent, CampaignViewComponent } from './components';
import { ReactNode, useEffect, useState } from "react";
import { UnorderedListOutlined, PlusOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Alert } from 'antd';

import { YcTabs, YcTitle } from "@/components";
import { profileService } from "@/services/profile.service";
import { useApps } from '@/modules/apps/pages/apps/use-apps';
import { useCampaigns } from '@/modules/advertising/pages/campaigns/useCampaigns';

import './docs-manage-campaigns.page.scss';

export function DocsManageCampaignsPage() {
  const [tab, _setTab] = useState("listing");
  const [managerKey, setManagerKey] = useState("");
  const { displayCampaigns } = useCampaigns(false);
  const { displayApps } = useApps();

  useEffect(() => {
    profileService.viewManagerOrReportKey("managerKey").then((key) => {
      setManagerKey(key || '');
    });
  }, []);

  const alertMessage = (): ReactNode => {
    return <Alert message="⚠️ WARNING! All API requests made using this documentation will interact directly with the production data ⚠️" type="error" />
  }

  return (
    <div id='docs-manage-campaigns'>
      <YcTitle label="Docs > Manage Campaigns"></YcTitle>
      <p>
        In this page you can manage campaigns
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "listing", label: "Listing", icon: <UnorderedListOutlined /> },
        { key: "view", label: "View", icon: <EyeOutlined /> },
        { key: "add", label: "Add", icon: <PlusOutlined /> },
        { key: "edit", label: "Edit", icon: <EditOutlined /> },
      ]} />
      <div className="doc-container">
        {!!managerKey ?
          <>
            {tab === "listing" &&
              <CampaignListingComponent apiKey={managerKey} />
            }
            {tab === "view" &&
              (displayCampaigns.length > 0 ? <CampaignViewComponent apiKey={managerKey} allCampaigns={displayCampaigns} /> : <div>Create an app before testing this</div>)
            }
            {tab === "add" &&
              <>
                {alertMessage()}
                <CampaignAddComponent apiKey={managerKey} displayApps={displayApps} />
              </>
            }
            {tab === "edit" &&
              <>
                {alertMessage()}
                {(displayCampaigns.length > 0 ? <CampaignEditComponent apiKey={managerKey} allCampaigns={displayCampaigns} /> : <div>Create an app before testing this</div>)}
              </>
            }
          </> : <div>You need to generate an api key <Link to='/profile'>here</Link> to use this documentation</div>
        }
      </div>
    </div>
  );
}
