import { formatMoney, formatPercent } from '@/components/platform-action-metadata/utils';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { ReportLineGraph, YcTitle } from '@/components';
import { usePageTitle } from '@/hooks/use-title';
import { MControlPanel, uniques } from '@/modeles';
import { controlPanelService_SA } from '@/services';

function filterReduceAverageDay<T extends { date: string; }>(data: T[], lastHour: number, property: keyof T, filter?: (e: T) => boolean) {
  return data
    .filter((d) => Number(d.date.split(' ')[1]) < lastHour && (filter ? filter(d) : true))
    .reduce((acc: { [key: string]: number; }, cur) => {
    const day = cur.date.split(' ')[0];
    if (!acc[day]) {
      acc[day] = 0
    }
    acc[day] += (+cur[property]);

    return acc;
  }, {});
}

function filterReduceAverageLastHour<T extends { date: string; }>(data: T[], lastHour: number, property: keyof T, filter?: (e: T) => boolean) {
  return data
    .filter((d) => Number(d.date.split(' ')[1]) === lastHour - 1 && (filter ? filter(d) : true))
    .reduce((acc: { [key: string]: number; }, cur) => {
      const day = cur.date.split(' ')[0];
      if (!acc[day]) {
        acc[day] = 0
      }
      acc[day] += (+cur[property]);

      return acc;
    }, {});
}

function getRoiStyle(roi: number, thresholds: [number, number]) {
  const baseStyle = {
    fontWeight: 'bold',
  }
  if (roi < thresholds[0]) {
    return {
      ...baseStyle,
      color: 'red',
    };
  }
  if (roi < thresholds[1]) {
    return {
      ...baseStyle,
      color: 'orange',
    };
  }
  return {
    ...baseStyle,
    color: 'green',
  };
}

function ControlPanelTodayTitlePercent({ percent }) {
  const style = getRoiStyle(percent, [-0.05, 0.05]);
  return (
    <span style={style}>{formatPercent(percent)}</span>
  )
}

function ControlPanelTodayTitle<T>({ title, keyD, keyH, average }: {
  title: string,
  keyD: keyof T,
  keyH: keyof T,
  average: T;
}) {
  return (
    <div>
      {title}<br/>
      <div className='row'>
        {average[keyD][2] && average[keyD][1] && average[keyD][0] && (
          <div className='col'>
            <span style={{
              color: '#acacac',
              fontSize: '14px',
            }} className="mx-2">
              D-1 : {formatMoney(average[keyD][1])} -{'>'} {formatMoney(average[keyD][2])} : <ControlPanelTodayTitlePercent percent={(average[keyD][2] / average[keyD][1]) - 1} />
            </span>
              <br />
              <span style={{ color: '#acacac', fontSize: '14px' }} className="mx-2">
              D-7 : {formatMoney(average[keyD][0])} -{'>'} {formatMoney(average[keyD][2])} : <ControlPanelTodayTitlePercent percent={(average[keyD][2] / average[keyD][0]) - 1} />
            </span>
            <br />
          </div>
        )}
        {average[keyH][2] && average[keyH][1] && average[keyH][0] && (
          <div className='col'>
            <span style={{
              color: '#acacac',
              fontSize: '14px',
            }} className="mx-2">
              D-1 : {formatMoney(average[keyH][1])} -{'>'} {formatMoney(average[keyH][2])} : <ControlPanelTodayTitlePercent percent={(average[keyH][2] / average[keyH][1]) - 1} />
            </span>
              <br />
              <span style={{ color: '#acacac', fontSize: '14px' }} className="mx-2">
              D-7 : {formatMoney(average[keyH][0])} -{'>'} {formatMoney(average[keyH][2])} : <ControlPanelTodayTitlePercent percent={(average[keyH][2] / average[keyH][0]) - 1} />
            </span>
            <br />
          </div>
        )}
      </div>
    </div>
  )
}

export function SAControlPanelTodayPage() {
  usePageTitle('Control panel today');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MControlPanel>(new MControlPanel());
  const height = 200;
  const todayDay = moment.utc().format('MM-DD 00');
  const yesterdayDay = moment.utc().subtract(1, 'days').format('MM-DD 00');

  function fetchData() {
    setLoading(true);
    controlPanelService_SA.viewToday().then((data) => {
      setData(data || new MControlPanel());
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => { fetchData() }, []);

  const average = useMemo(() => {
    const today = data.ynYsoVsCustomer.filter((r) => r.date.split(' ')[0] === todayDay.split(' ')[0]);
    const allHours = uniques(today.map((r) => Number(r.date.split(' ').pop() || '0') as number).sort((a, b) => b - a));
    const lastHour = allHours.length > 1 ? allHours[1] : 1;
    const accountFilter = (d: { account: string; }) => d.account === 'global';
    const uaByDay = filterReduceAverageDay(data.ynCustomerUA, lastHour, 'spend', accountFilter);
    const monetByDay = filterReduceAverageDay(data.ynCustomerMonet, lastHour, 'revenue', accountFilter);
    const uaLastHour = filterReduceAverageLastHour(data.ynCustomerUA, lastHour, 'spend', accountFilter);
    const monetLastHour = filterReduceAverageLastHour(data.ynCustomerMonet, lastHour, 'revenue', accountFilter);
    const uaByDayAll = filterReduceAverageDay(data.ynYsoVsCustomer, lastHour, 'sTotal');
    const monetByDayAll = filterReduceAverageDay(data.ynYsoVsCustomer, lastHour, 'rTotal');
    const uaLastHourAll = filterReduceAverageLastHour(data.ynYsoVsCustomer, lastHour, 'sTotal');
    const monetLastHourAll = filterReduceAverageLastHour(data.ynYsoVsCustomer, lastHour, 'rTotal');
    const sov = data.maxYsoRevenue
      .filter((d) => Number(d.date.split(' ')[1]) < lastHour)
      .reduce((acc: {[key: string]: { yso: number; withBanner: number; withoutBanner: number; }}, cur) => {
        const day = cur.date.split(' ')[0];
        if (!acc[day]) {
          acc[day] = {
            yso: 0,
            withBanner: 0,
            withoutBanner: 0,
          }
        }
        acc[day].yso += cur.revenue;
        acc[day].withBanner += cur.totalRevenue;
        acc[day].withoutBanner += cur.totalRevenueNoBanner;

        return acc;
      }, {});

    return {
      uaByDay: Object.entries(uaByDay).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      uaLastHour: Object.entries(uaLastHour).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      monetByDay: Object.entries(monetByDay).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      monetLastHour: Object.entries(monetLastHour).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      uaByDayAll: Object.entries(uaByDayAll).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      monetByDayAll: Object.entries(monetByDayAll).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      uaLastHourAll: Object.entries(uaLastHourAll).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      monetLastHourAll: Object.entries(monetLastHourAll).sort(([kA], [kB]) => kA.localeCompare(kB)).map(([, v]) => v),
      sov: Object.entries(sov).map(([, value]) => (
        {
          withBanner: value.yso / value.withBanner,
          withoutBanner: value.yso / value.withoutBanner,
        })),
      lastHour,
    };
  }, [data.maxYsoRevenue, data.ynCustomerMonet, data.ynCustomerUA, data.ynYsoVsCustomer, todayDay]);

  const commonGraphProps = { height: height, verticalLineKeys: [yesterdayDay, todayDay] };

  return (
    <Spin spinning={loading}>
      <div className='d-flex'>
        <YcTitle label={`Control Panel Today 0->${(average.lastHour || 1) - 1}h`} /><Button type="link" onClick={() => fetchData()}><ReloadOutlined /></Button>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <ControlPanelTodayTitle title='Customer UA' keyD='uaByDay' keyH='uaLastHour' average={average} />
          <ReportLineGraph data={data.ynCustomerUA} is={['account']} fields={['spend']} {...commonGraphProps} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <ControlPanelTodayTitle title='Customer Monetization' keyD='monetByDay' keyH='monetLastHour' average={average} />
          <ReportLineGraph data={data.ynCustomerMonet} is={['account']} fields={['revenue']} {...commonGraphProps} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <div>Financial traffic</div>
          <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'rCustomer', 'diffYso']} {...commonGraphProps} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <ControlPanelTodayTitle title='UA' keyD='uaByDayAll' keyH='uaLastHourAll' average={average} />
          <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'sYsoCorp', 'sTotal']} {...commonGraphProps} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <ControlPanelTodayTitle title='Monet' keyD='monetByDayAll' keyH='monetLastHourAll' average={average} />
          <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['rCustomer', 'rYsoCorp', 'rTotal']} {...commonGraphProps} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>UA: Android VS iOS</div>
          <ReportLineGraph data={data.ynPlatformRoi} is={[]} fields={['uaAndroid', 'uaIos']} {...commonGraphProps} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Monet: Android VS iOS</div>
          <ReportLineGraph data={data.ynPlatformRoi} is={[]} fields={['monetAndroid', 'monetIos']} {...commonGraphProps} />
        </div>
      </div>
      <div className="mb-4">
        <div>Max SOV (YSO Corp only)</div>
        <ReportLineGraph
          data={data.maxYsoSov}
          is={[]}
          fields={['sov']}
          {...commonGraphProps}
        />
      </div>
    </Spin>
  );
}
