import { MPlacement } from "@/modeles";
import { placementsService } from "@/services";
import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

export function usePlacements() {
  const [placements, _setPlacements] = useState<MPlacement[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayPlacements, setDisplayPlacements] = useState<MPlacement[]>([]);
  const uHistory = useHistory();

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = useCallback((event, overwritePlacements?: MPlacement[]) => {
    const { value } = event.target;

    if (value) {
      const dPlacements = (overwritePlacements || placements).filter((a) => {
        const checkName = a.name.toLowerCase().includes(value?.toLowerCase());
        const checkIosBundle = a.key.toLowerCase().includes(value?.toLowerCase());

        return checkName || checkIosBundle;
      });

      setDisplayPlacements(dPlacements);
    } else {
      setDisplayPlacements((overwritePlacements || placements));
    }
  }, [placements]);

  useEffect(() => {
    setLoading(true);
    placementsService.listing(null).then((data) => {
      setLoading(false);
      if (data) {
        _setPlacements(data);
        // @ts-ignore
        onSearch({ target: { value: '' } }, data);
      } else {
        uHistory.push("/");
      }
    }).catch((e) => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, onSearch, displayPlacements };
}