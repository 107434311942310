export interface Total {
  requests: number;
  responses: number;
  noBids: number;
  bidRate: number;
  wins: number;
  winRate: number;
  loss: number;
  lossRate: number;
  errors: number;
  errorRate: number;
  bills: number;
  impressions: number;
  displayRate: number;
  revenue: number;
  ecpm: number;
}

export interface ResultLine extends Total {
  id: number;
  date?: string;
  hourOfDay?: string;
  endpoint?: string;
  platform?: string;
  platformVersion?: string;
  source?: string;
  placementType?: string;
  countryCode?: string;
  lossCode?: string;
}

export const PARAMS_FILTERS = ['granularities', 'hours', 'endpoints', 'platforms', 'platformVersions', 'placementTypes', 'countries', 'sources', 'lossCodes'];
export const IS_FILTERS = [
  { filterKey: 'isGranularity', searchKey: 'granularity' },
  { filterKey: 'isHourOfDay', searchKey: 'hourOfDay' },
  { filterKey: 'isEndpoint', searchKey: 'endpoint' },
  { filterKey: 'isPlatform', searchKey: 'platform' },
  { filterKey: 'isPlatformVersion', searchKey: 'platformVersion' },
  { filterKey: 'isPlacementType', searchKey: 'placementType' },
  { filterKey: 'isCountry', searchKey: 'country' },
  { filterKey: 'isSource', searchKey: 'source' },
  { filterKey: 'isLossCode', searchKey: 'lossCode' },
];