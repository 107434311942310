import { ReactNode, useEffect, useState } from "react";
import { UnorderedListOutlined, PlusOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Alert } from "antd";
import { Link } from "react-router-dom";

import { YcTabs, YcTitle } from "../../../../components";
import { profileService } from "../../../../services/profile.service";
import { PlacementAddComponent, PlacementEditComponent, PlacementListingComponent, PlacementViewComponent } from "./components";
import { useApps } from "@/modules/apps/pages/apps/use-apps";
import { usePlacements } from "@/modules/publishing/pages/placements/use-placements";

import './docs-manage-placements.page.scss';

export function DocsManagePlacementsPage() {
  const [tab, _setTab] = useState("listing");
  const [managerKey, setManagerKey] = useState("");
  const { displayApps } = useApps();
  const { displayPlacements } = usePlacements();

  useEffect(() => {
    profileService.viewManagerOrReportKey("managerKey").then((key) => {
      setManagerKey(key || '');
    });
  }, []);

  const alertMessage = (): ReactNode => {
    return <Alert message="⚠️ WARNING! All API requests made using this documentation will interact directly with the production data ⚠️" type="error" />
  }

  return (
    <div id='docs-manage-campaigns'>
      <YcTitle label="Docs > Manage Placements"></YcTitle>
      <p>
        In this page you can manage placements
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "listing", label: "Listing", icon: <UnorderedListOutlined /> },
        { key: "view", label: "View", icon: <EyeOutlined /> },
        { key: "add", label: "Add", icon: <PlusOutlined /> },
        { key: "edit", label: "Edit", icon: <EditOutlined /> },
      ]} />
      <div className="doc-container">
        {!!managerKey ?
          <>
            {tab === "listing" &&
              <PlacementListingComponent apiKey={managerKey} />
            }
            {tab === "view" &&
              (displayPlacements.length > 0
                ? <PlacementViewComponent apiKey={managerKey}  displayPlacements={displayPlacements} />
                : <div>Create an app and a placement before testing this</div>)
            }
            {tab === "add" &&
              <>
                {alertMessage()}
                {(displayApps.length > 0
                ? <PlacementAddComponent apiKey={managerKey} displayApps={displayApps} />
                : <div>Create an app and a placement before testing this</div>)}
              </>
            }
            {tab === "edit" &&
              <>
                {alertMessage()}
                {(displayPlacements.length > 0
                ? <PlacementEditComponent apiKey={managerKey} displayPlacements={displayPlacements} />
                : <div>Create an app and a placement before testing this</div>)}
                
              </>
            }
          </> : <div>You need to generate an api key <Link to='/profile'>here</Link> to use this documentation</div>
        }
      </div>
    </div>
  );
}