import { JsonViewer } from '@/components/json-viewer/json-viewer';
import React, { useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import axios from 'axios';

import { QueryCodeViewer } from '@/modules/docs/components';

import './app-listing-component.css';

const { Title } = Typography;

interface AppListingComponentProps {
  apiKey: string;
}

interface listingParams {
  fields?: string[];
}

const fieldOptions = [ 'platform', 'bundleId', 'appleId', 'storeUrl', 'icon'];
const baseUrl = `${process.env.REACT_APP_API_URL}/api/apps/listing`;

const resultSchema = JSON.parse(`{
  "data": [
    {
      "key": "",
      "name": "",
      "platform": "ios|android",
      "bundleId": "",
      "appleId": "",
      "isCallbackCreated": true,
      "storeUrl": "",
      "Icon": {
        "url": ""
      },
      "nbCampaigns": 0,
      "nbCampaignsActive": 0,
      "nbPlacements": 0,
      "nbPlacementsActive": 0
    }
  ],
  "date": 0
}`);

export const AppListingComponent: React.FC<AppListingComponentProps> = ({ apiKey }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<listingParams>();

  const onChangeParams = (values: listingParams) => {
    const updatedParams = { ...params };
    Object.keys(values)?.forEach((property) => {
      if (values[property] === null || values[property] === undefined || values[property].length <= 0) {
        delete updatedParams[property];
        setParams(updatedParams);
      } else {
        setParams({ ...params, [property]: values[property] });
      }
    })
  };

  const fetchAppListing = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(baseUrl, {
        headers: { Authorization: apiKey },
        params: params?.fields?.length ? { fields: params?.fields.join(',') } : undefined,
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app-listing-component">
      <Title level={3}>API Documentation: Get App Listings</Title>
      <Form layout="vertical" onValuesChange={(values) => { onChangeParams(values); }}>
        <Form.Item label="Fields" name='fields'>
          <Select
            mode="multiple"
            placeholder="Select fields to include in the response"
            value={params?.fields}
            options={fieldOptions.map(field => ({ value: field }))}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetchAppListing} loading={isLoading}>
            Fetch App Listings
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={baseUrl} apiKey={apiKey} method='get' params={params} />
    </div>
  );
};
