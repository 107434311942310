export class MUser {
  id: number = 0;
  email: string = "";
  isActive: boolean = true;
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  activeNotificationCap: boolean = true;
  createdAt?: Date;
  accountId?: number;
  Permissions?: { path: string }[];
}

export class MUserApi {
  id?: number = 0;
  name: string = "";
  reportKey: string = "";
  managerKey: string = "";
  isActive: boolean = true;
  Permissions?: { path: string }[];
}
