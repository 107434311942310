import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import { App } from './modules/app';
import reportWebVitals from './reportWebVitals';
import { AuthsContextProvider, CapContextProvider } from "./contexts";

ReactGA.initialize('G-LFF5MEFXQ4');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthsContextProvider>
        <CapContextProvider>
          <App />
        </CapContextProvider>
      </AuthsContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
