export interface Total {
  requests: number;
  responses: number;
  fillRate: number;
  wins: number;
  winRate: number;
  impressions: number;
  revenue: number;
  ecpm: number;
}

export interface ResultLine extends Total {
  id: number;
  date?: string;
  hourOfDay?: string;
  publisher?: string;
  platform?: string;
  platformVersion?: string;
  sdkVersion?: string;
  source?: string;
  placement?: string;
  placementType?: string;
  countryCode?: string;
  isMediation?: boolean;
}

export const PARAMS_FILTERS = ['granularities', 'hours', 'platforms', 'platformVersions', 'placements', 'placementTypes', 'countries', 'sources'];
export const IS_FILTERS = [
  { filterKey: 'isGranularity', searchKey: 'granularity' },
  { filterKey: 'isHourOfDay', searchKey: 'hourOfDay' },
  { filterKey: 'isPlatform', searchKey: 'platform' },
  { filterKey: 'isPlatformVersion', searchKey: 'platformVersion' },
  { filterKey: 'isPlacementType', searchKey: 'placementType' },
  { filterKey: 'isCountry', searchKey: 'country' },
  { filterKey: 'isSource', searchKey: 'source' },
  { filterKey: 'isPlacement', searchKey: 'placement' },
];