import React, { useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import axios from 'axios';

import { JsonViewer } from '@/components';
import { QueryCodeViewer } from '@/modules/docs/components';
import { MPlacement } from '@/modeles';

import './placement-view-component.css';

const { Title } = Typography;

interface PlacementViewComponentProps {
  apiKey: string;
  displayPlacements: MPlacement[];
}

interface viewParams {
  fields?: string[];
  applicationFields?: string[];
}

const fieldOptions = ["type", "applicationKey", "isActive"];
const applicationFieldOptions = ["platform", "bundleId", "appleId", "isCallbackCreated", "storeUrl", "icon", "nbCampaigns", "nbPlacements"];
const baseUrl = `${process.env.REACT_APP_API_URL}/api/placements/view`;

const resultSchema = JSON.parse(`{
  "data": {
    "key": "",
    "name": "",
    "type": "interstitial|rewarded|banner",
    "isActive": true,
    "applicationKey": "",
    "Application": {
      "key": "",
      "name": "",
      "platform": "ios|android",
      "bundleId": "",
      "appleId": "",
      "isCallbackCreated": true,
      "storeUrl": "",
      "Icon": {
        "url": ""
      },
      "nbCampaigns": 0,
      "nbCampaignsActive": 0,
      "nbPlacements": 0,
      "nbPlacementsActive": 0
    }
  },
  "date": 0
}`);

export const PlacementViewComponent: React.FC<PlacementViewComponentProps> = ({ apiKey, displayPlacements }) => {
  const [apiResponse, setApiResponse] = useState<object | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [key, setKey] = useState<string>(displayPlacements[0].key);
  const [params, setParams] = useState<viewParams>();

  const onChangeParams = (values: viewParams) => {
    const updatedParams = { ...params };
    Object.keys(values)?.forEach((property) => {
      if (values[property] === null || values[property] === undefined || values[property].length <= 0) {
        delete updatedParams[property];
        setParams(updatedParams);
      } else {
        setParams({ ...params, [property]: values[property] });
      }
    })
  };

  const fetchApp = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/${key}`, {
        headers: { Authorization: apiKey },
        params: (params?.fields?.length || params?.applicationFields?.length) ? {
          ...(params?.fields?.length ? { fields: params.fields.join(',') } : {}),
          ...(params?.applicationFields?.length ? { applicationFields: params.applicationFields.join(',') } : {}),
        } : undefined,
      });
      setApiResponse(response.data);
    } catch (error) {
      // @ts-ignore
      setApiResponse(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app-view-component">
      <Title level={3}>API Documentation: Get Placement</Title>
      <Form layout="vertical" onValuesChange={(values) => { onChangeParams(values); }}>
        <Form.Item label="Key">
          <Select
            placeholder="Choose a placement"
            showSearch
            value={key}
            onChange={(value) => {setKey(value)}}
            options={displayPlacements.map((placement) => ({ value: placement.key, label: `${placement.Application?.key} - ${placement.name}` }))}
          />
        </Form.Item>
        <Form.Item label="Fields" name='fields'>
          <Select
            mode="multiple"
            placeholder="Select fields to include in the response"
            value={params?.fields}
            options={fieldOptions.map(field => ({ value: field }))}
          />
        </Form.Item>
        <Form.Item label="App Fields" name='applicationFields'>
          <Select
            mode="multiple"
            placeholder="Select app fields to include in the response"
            value={params?.applicationFields}
            options={applicationFieldOptions.map(field => ({ value: field }))}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetchApp} loading={isLoading}>
            Fetch Placement
          </Button>
        </Form.Item>
      </Form>

      <div className="response-section">
        <Title level={4}>API Response</Title>
        <JsonViewer jsonData={apiResponse} schema={resultSchema} />
      </div>

      <QueryCodeViewer url={`${baseUrl}/${key}`} apiKey={apiKey} method='get' params={params} />
    </div>
  );
};
