import { Spin } from 'antd';
import moment from 'moment';
import qs from 'qs';
import { useMemo } from 'react';

import { ReportLineGraph, YcColumnType, YcTable, YcTitle } from '@/components';
import { FilterTool, NumberTools } from '@/tools';
import { reportingBiddingService } from '@/services';
import { usePageTitle, useReportingTable } from '@/hooks';
import { getMetricColumn, getStringColumn } from '@/modules/reporting/helpers';
import { ReportingFilter } from '../../components';
import { IS_FILTERS, PARAMS_FILTERS, ResultLine, Total } from '../../constant/bidding.constant';

import './reporting-bidding.page.scss';

interface Result {
  is: string[];
  total: Total;
  results: ResultLine[];
}

function getParams(stringify: boolean, dates: [moment.Moment, moment.Moment], filters: Filters, params: any = {}) {
  const b = {
    ...(params || {}),
    dateStart: moment(dates[0]).format("YYYY-MM-DD"),
    dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
    granularity: filters?.isGranularity ? filters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
    hours: FilterTool.getParamsFilter(filters?.hours),
    endpoints: FilterTool.getParamsFilter(filters?.endpoints),
    countries: FilterTool.getParamsFilter(filters?.countries),
    sources: FilterTool.getParamsFilter(filters?.sources),
    platforms: FilterTool.getParamsFilter(filters?.platforms),
    platformVersions: FilterTool.getParamsFilter(filters?.platformVersions),
    placementTypes: FilterTool.getParamsFilter(filters?.placementTypes),
    lossCodes: FilterTool.getParamsFilter(filters?.lossCodes),
    is: FilterTool.getParamsIs(IS_FILTERS, filters),
  };
  if (stringify) {
    return qs.stringify(b, { encode: false });
  }
  return b;
}

export function ReportingBiddingPage() {
  usePageTitle('Reporting - Bidding');

  const {
    filters, results, tableData, dates,
    loading, onSetFilter, _setTableData, onDatesChange,
    onSetFilters, onSearchReportingFilter, onSearchDownloadData,
  } = useReportingTable<Result, ResultLine>({
    service: reportingBiddingService,
    getParams,
    isFilters: IS_FILTERS,
    paramsFilters: PARAMS_FILTERS,
    initFCItemStates: FilterTool.initFCItemStates,
  });

  const columnsMemo = useMemo(() => {
    const allBCols = {
      granularity: getStringColumn<ResultLine>({ title: 'Date', dataKey: 'date', filters, onSetFilter }),
      hourOfDay: getStringColumn<ResultLine>({ title: 'Hour', dataKey: 'hourOfDay', itemKey: 'hours', filters, onSetFilter }),
      endpoint: getStringColumn<ResultLine>({ title: 'Endpoint', dataKey: 'endpoint', itemKey: 'endpoints', filters, onSetFilter }),
      lossCode: getStringColumn<ResultLine>({ title: 'Loss Code', dataKey: 'lossCode', itemKey: 'lossCodes', filters, onSetFilter }),
      platform: getStringColumn<ResultLine>({ title: 'Platform', dataKey: 'platform', itemKey: 'platforms', filters, onSetFilter }),
      platformVersion: getStringColumn<ResultLine>({ title: 'Platform Version', dataKey: 'platformVersion', itemKey: 'platformVersions', filters, onSetFilter }),
      source: getStringColumn<ResultLine>({ title: 'Source', dataKey: 'source', itemKey: 'sources', filters, onSetFilter }),
      placementType: getStringColumn<ResultLine>({ title: 'Placement Type', dataKey: 'placementType', itemKey: 'placementTypes', filters, onSetFilter }),
      country: getStringColumn<ResultLine>({ title: 'Country', dataKey: 'countryCode', itemKey: 'countries', filters, onSetFilter }),
    }

    const columns: YcColumnType<ResultLine>[] = [
      getMetricColumn({ title: 'Requests', dataKey: 'requests', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'No Bids', dataKey: 'noBids', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Responses', dataKey: 'responses', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Bid Rate', dataKey: 'bidRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Wins', dataKey: 'wins', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Win Rate', dataKey: 'winRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Loss', dataKey: 'loss', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Loss Rate', dataKey: 'lossRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Errors', dataKey: 'errors', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Error Rate', dataKey: 'errorRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Impressions', dataKey: 'impressions', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Display Rate', dataKey: 'displayRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Billable Impressions', dataKey: 'bills', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Revenue', dataKey: 'revenue', numberFormat: NumberTools.roundPrice3Dec, config: { defaultSortOrder: 'descend' }, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'eCPM', dataKey: 'ecpm', numberFormat: NumberTools.roundPrice3Dec, results: results?.results || [], _setTableData }),
    ];

    let bCols: YcColumnType<ResultLine>[] = Object.entries(allBCols).filter(([key]) => results?.is.includes(key)).map(([_, col]) => col);
    if (bCols.length) {
      columns.unshift({ title: 'Breakdown', dataIndex: 'breakdown', key: 'breakdown', ycCanNotHide: true, children: bCols });
    }
    return columns;
  }, [_setTableData, filters, onSetFilter, results?.is, results?.results]);

  const summaries = results
    ? [
        { key: 'generic', value: undefined, colSpan: results!.is.length },
        { key: 'requests', value: NumberTools.largeNumber(results!.total.requests) },
        { key: 'noBids', value: NumberTools.largeNumber(results!.total.noBids) },
        { key: 'responses', value: NumberTools.largeNumber(results!.total.responses) },
        { key: 'bidRate', value: NumberTools.roundPercentage(results!.total.bidRate, 2) },
        { key: 'wins', value: NumberTools.largeNumber(results!.total.wins) },
        { key: 'winRate', value: NumberTools.roundPercentage(results!.total.winRate, 2) },
        { key: 'loss', value: NumberTools.largeNumber(results!.total.loss) },
        { key: 'lossRate', value: NumberTools.roundPercentage(results!.total.lossRate, 2) },
        { key: 'errors', value: NumberTools.largeNumber(results!.total.errors) },
        { key: 'errorRate', value: NumberTools.roundPercentage(results!.total.errorRate, 2) },
        { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
        { key: 'displayRate', value: NumberTools.roundPercentage(results!.total.displayRate, 2) },
        { key: 'bills', value: NumberTools.largeNumber(results!.total.bills) },
        { key: 'revenue', value: NumberTools.roundPrice(results!.total.revenue, 3) },
        { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },
      ]
    : undefined;

  const filterProps: FilterPropType[] = useMemo(
    () => [
      { key: 'granularities', itemKey: 'granularities', isKey: 'isGranularity', label: 'Granularity' },
      { key: 'hours', itemKey: 'hours', isKey: 'isHourOfDay', label: 'Hours', mode: 'multiple' as const },
      { key: 'endpoints', itemKey: 'endpoints', isKey: 'isEndpoint', label: 'Endpoints', mode: 'multiple' as const },
      { key: 'countries', itemKey: 'countries', isKey: 'isCountry', label: 'Countries', mode: 'multiple' as const },
      { key: 'sources', itemKey: 'sources', isKey: 'isSource', label: 'Sources', mode: 'multiple' as const },
      { key: 'platforms', itemKey: 'platforms', isKey: 'isPlatform', label: 'Platforms', mode: 'multiple' as const },
      { key: 'platformVersions', itemKey: 'platformVersions', isKey: 'isPlatformVersion', label: 'Platform Versions', mode: 'multiple' as const },
      { key: 'placementTypes', itemKey: 'placementTypes', isKey: 'isPlacementType', label: 'Placement Types', mode: 'multiple' as const },
      { key: 'lossCodes', itemKey: 'lossCodes', isKey: 'isLossCode', label: 'Loss Code', mode: 'multiple' as const },
    ].map((f) => ({ ...f, filters: filters, onSetFilters: onSetFilters, show: true })),
    [filters, onSetFilters],
  );

  return (
    <div id="reporting-bidding">
      <YcTitle label="Reporting - Bidding"></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter
            datePresets={['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date'] as const}
            dates={dates}
            filterProps={filterProps}
            loading={loading}
            onDatesChange={onDatesChange}
            onSearch={onSearchReportingFilter}
            onSearchDownloadData={onSearchDownloadData}
          />
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['revenue']} />
            </div>
            <YcTable bordered size="small" dataSource={tableData} columns={columnsMemo} ycSummarys={summaries} ycTableKey="reporting-bidding" rowKey="id" />
          </div>
        )}
      </Spin>
    </div>
  );
}
